@import '@labradorsports/components/variables.scss';

.share-my-items-team {
    .share-list {
        overflow: auto;
        flex: 1 1 auto;
    }

    .modification-container {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        .team-name {
            flex: 0 0 60%;
        }
    }

    .upsell-container {
        flex: 1 1 auto;
        position: relative;
    }
}

.share-dropdown-team-name {
    flex: 1 1 auto;
}

@import '@labradorsports/components/variables.scss';

.playbook-side-panel {
    display: flex;
    flex-direction: column;
    height: 100%;

    .team-playbook-panel {
        flex-basis: calc(100% - $buttons-bar-height);
        flex-grow: 1;

        .play-list {
            padding-bottom: $buttons-bar-height;
        }

        .move-to-folder-container {
            bottom: $buttons-bar-height;
        }
    }

    .peek-panel .opener {
        background: white;

        .small-logo {
            width: 48px;
            height: 48px;
            position: absolute;
            opacity: 0.3;
            left: 50%;
            margin-left: -24px;
            top: 0;
        }

        .MuiTab-root,
        .MuiTabs-root {
            min-height: 52px;
            min-width: auto;
        }
    }

    .community-profile-setup {
        display: flex;
        flex-direction: column;
        height: 100%;

        & > :first-child {
            flex-grow: 1;
        }
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.custom-field-designer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .field-outline-container {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        touch-action: none;
    }

    .field-outline {
        border: 2px solid #777;
        position: relative;
    }

    $resize-handle-width: 70px;

    .resize-handle {
        position: absolute;
        width: $resize-handle-width;
        height: 26px;
        background: #ccc;
        border-radius: 5px;
        box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);

        .resize-line {
            width: 80%;
            margin-left: 10%;
            margin-top: 6px;
            height: 1px;
            background: #777;
            display: block;
        }

        &.vertical {
            left: 50%;
            margin-left: calc($resize-handle-width / -2);
            top: -13px;
            cursor: ns-resize;
        }

        &.horizontal {
            transform: rotate(90deg);
            top: 50%;
            margin-top: -13px;
            right: calc($resize-handle-width / -2);
            cursor: ew-resize;
        }
    }

    .field-rule {
        position: absolute;
        text-align: center;

        &:before {
            content: '';
            border: 2px solid #777;
            position: absolute;
        }

        .dimension-value {
            display: inline-block;
            position: relative;
            z-index: 1;
            width: $resize-handle-width;
            text-align: center;
        }

        &.width {
            bottom: 10px;
            width: calc(100% - 30px);
            left: 20px;
            height: 24px;
            border-bottom: 2px solid #777;
            text-align: center;

            &:before {
                height: 10px;
                left: 0;
                right: 0;
                bottom: -6px;
                border-top: none;
                border-bottom: none;
            }
        }

        &.height {
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: calc(100% - 30px);
            left: 10px;
            border-left: 2px solid #777;
            width: $resize-handle-width;
            top: 10px;

            &:before {
                width: 10px;
                top: 0;
                bottom: 0;
                left: -6px;
                border-left: none;
                border-right: none;
            }
        }
    }
}

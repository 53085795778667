@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.view-options-menu {
    background: white;
    border-radius: 4px;
    box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);
    width: 170px;
    padding: 10px;
    z-index: 12;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    label {
        width: 100%;
    }

    .view-option-checkbox {
        margin: 0;
        padding: 4px 12px;
    }

    @include media-breakpoint-down(sm) {
        .view-option-checkbox {
            padding: 8px 12px;
        }
    }
}

@import '@labradorsports/components/variables.scss';

.event-details-page {
    padding-bottom: $buttons-bar-height;

    .event-type {
        text-transform: uppercase;
    }

    .panel-content {
        padding: 0;
        position: static;
    }

    .event-header {
        background: var(--event-color);
        color: white;
        padding: 1rem;
        position: relative;
    }

    .buttons-bar {
        display: flex;
        align-items: center;
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.create-team-review-page {
    .color-swatch {
        display: inline-block;
        border: 3px solid #ccc;
        border-radius: 1px;
        width: 35px;
        height: 35px;
        margin-right: 0.5rem;
        vertical-align: middle;
    }

    .program-info {
        flex-basis: 50%;
    }

    .program-name {
        flex: 1 1 30%;
    }

    .program-branding {
        flex: 1 1 70%;
    }

    .program-logo {
        width: 75px;
        height: 75px;
        margin-right: 2rem;
        vertical-align: middle;
    }

    .team-info-review-card {
        display: flex;
        flex-direction: row;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
    }

    @include media-breakpoint-down(sm) {
        .team-branding {
            margin-top: 1rem;
        }

        .team-info-review-card {
            flex-wrap: wrap;

            .team-addon-counts {
                flex-basis: 100%;
                order: 2;
            }
        }
    }
}

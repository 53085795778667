@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.create-play {
    min-height: 100%;

    .header-label {
        padding-left: 15px;
        display: block;
        font-size: 16px;
        text-align: left;
        text-transform: uppercase;
    }

    .create-play-form {
        width: 100%;
        max-width: 400px;
        display: inline-block;
    }

    .buttons .MuiButton-root {
        min-width: 150px;
        margin: 0 10px;

        &.MuiButton-text {
            color: white;
        }
    }

    .create-play-form {
        width: 100%;
        max-width: 400px;
        display: inline-block;

        .create-play-input {
            text-align: left;

            .MuiInputLabel-root {
                color: white;
            }

            .MuiOutlinedInput-root {
                &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
                &.Mui-error fieldset.MuiOutlinedInput-notchedOutline,
                &:hover fieldset.MuiOutlinedInput-notchedOutline {
                    border-color: white;
                }

                fieldset.MuiOutlinedInput-notchedOutline {
                    border-color: rgba(255, 255, 255, 0.65);
                }
            }
        }

        .play-type-options {
            margin-left: -10px;
            margin-right: -10px;
            margin-bottom: 10px;
        }

        .play-type {
            display: inline-block;
            border: 1px solid white;
            border-radius: 15px;
            width: calc(33% - 20px);
            margin: 10px;
            cursor: pointer;

            img {
                margin: 10px;
                width: 80%;
                height: 80%;

                &.half-field {
                    width: 70%;
                    height: 70%;
                    margin-top: 18px;
                }
            }

            &:hover {
                box-shadow: 0px 0px 5px 1px white;
            }

            &.selected {
                box-shadow: 0px 0px 5px 3px white;
            }
        }

        .error-text {
            display: inline-block;
            width: 400px;
            text-align: left;
            color: white;
            padding-left: 15px;
        }

        .tag-container {
            width: 49%;
        }

        .tag-checkbox {
            color: white;
        }

        .MuiCheckbox-colorSecondary.Mui-checked {
            color: white;
        }

        @include media-breakpoint-down(sm) {
            .play-type-options {
                margin-left: -5px;
                margin-right: -5px;
            }

            .play-type {
                width: calc(33% - 10px);
                margin: 5px;
            }
        }
    }
}

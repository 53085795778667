@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.video-url-input {
    position: absolute;
    bottom: 100%;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    min-width: 400px;

    .video-url-form {
        padding: 10px 15px;
    }

    .video-thumbnail {
        width: 160px;
        height: 90px;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        min-width: auto;
    }
}

.team-toggle {
    width: 74px;
    height: 60px;
    position: relative;
    border-right: 1px solid #ddd;
    cursor: pointer;

    .player-icon {
        transition: all 0.2s ease-in;
        position: absolute;
        top: 12px;
        left: 18px;

        &.active {
            transform: scale(1.3);
            z-index: 1;
            top: 22px;
            left: 28px;
        }
    }
}

.documents-list-panel {
    display: flex;
    flex-direction: column;
    min-height: 400px;

    &.card {
        padding: 1rem;
    }

    .documents-list {
        flex: 1 1 auto;
        margin-bottom: 1rem;
    }

    .document-list-item {
        display: flex;
        border-bottom: 1px solid #ccc;
        align-items: center;
        cursor: pointer;
    }

    .document-icon {
        width: 32px;
        height: 32px;
    }

    .document-name {
        overflow: hidden;
        line-height: 1.5rem;
        max-height: 4.5rem;
    }

    .document-details {
        flex-grow: 1;
        padding: 0.5rem;
    }
}

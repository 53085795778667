@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.animated-modal {
    overflow: auto;

    .MuiPaper-root {
        opacity: 0;
        transform: translateY(-20px);
        transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    }

    .buttons-bar {
        height: auto;
    }
}

.animated-modal-appear-active,
.animated-modal-enter,
.animated-modal-enter-done,
.animated-modal-exit {
    .MuiPaper-root {
        opacity: 1;
        transform: translateY(0);
    }
}

.animated-modal-exit-active {
    .MuiPaper-root {
        opacity: 0;
        transform: translateY(-20px);
    }
}

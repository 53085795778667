@import '@labradorsports/components/variables.scss';

.add-comment-form {
    border-top: 1px solid #ccc;
    border-left: 5px solid $primary-blue;

    .comment-details {
        background: #ddd;
    }

    .buttons-bar {
        padding: 0.5rem;
    }
}

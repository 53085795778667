.attendance-summary {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .metric-placeholder {
        display: inline-block;
        padding: 0 2rem;
        background: #ccc;
        color: #ccc;
        font-size: 4rem;
        line-height: 1;
        border-radius: 0.5rem;
    }

    .summary-metric {
        text-align: center;
    }

    .metric-value {
        font-size: 4rem;
        line-height: 1;

        .small {
            font-size: 1rem;
        }
    }
}

@import '@labradorsports/components/variables.scss';

.game-card-wrapper {
    display: inline-block;

    .game-card {
        position: relative;
        border-left: 4px solid $orange;
        width: 270px;
        margin-right: 1rem;
        height: 120px;
        vertical-align: middle;

        &.past-game {
            border-left-color: #ccc;
        }
    }

    .game-team {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .game-details {
        position: absolute;
        right: 0.5rem;
        bottom: 0.5rem;
    }
}

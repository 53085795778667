.add-existing-personnel {
    .personnel-card-wrapper {
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        .card {
            margin-bottom: 0;
        }

        &.selected .card {
            background: #f7f7f7;
        }

        &:hover .card {
            background: #f0f0f0;
        }

        .MuiFormControlLabel-root {
            margin-right: 0;
        }
    }

    .quill-wrapper {
        cursor: pointer;
        pointer-events: none;
    }

    .MuiFormControl-root {
        width: 150px;
    }
}

.coach-list {
    .coach-table {
        border: 1px solid #ddd;
    }

    .contact-card {
        &:nth-child(even) {
            background: #eee;
        }
    }

    .multi-select {
        display: flex;
        align-items: center;

        &.active {
            background: $orange;
            color: white;
        }

        .MuiFormControlLabel-root {
            margin: 0;
        }
    }
}

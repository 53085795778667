@import '@labradorsports/components/variables.scss';

.profile-page {
    .profile-setup-content {
        height: calc(100% - 30px);

        & > .panel-content {
            display: flex;
        }
    }

    .image-resize {
        flex: 1 1 auto;
        overflow: auto;
    }

    .profile-setup-menu {
        .profile-menu-header {
            background: $primary-blue;
            color: white;
            display: flex;
            align-items: end;

            .profile-image-editor {
                vertical-align: middle;

                .MuiButton-root {
                    color: white;
                }
            }

            .profile-name {
                vertical-align: middle;
                display: inline-block;
                line-height: normal;
                font-size: 26px;
                overflow: hidden;
                text-overflow: ellipsis;

                .small {
                    font-size: 14px;
                }
            }
        }
    }

    .checkbox-list.two-cols {
        margin-bottom: 15px;
    }

    .profile-links {
        .profile-link {
            display: block;
            padding: 1rem;
            cursor: pointer;
            color: #212529;
            text-decoration: none;

            &:hover {
                background: darken(white, 5%);
            }

            &.disabled {
                cursor: default;
                color: lighten(#212529, 50%);

                &:hover {
                    background: none;
                }

                .profile-link-icon {
                    opacity: 0.4;
                }
            }

            .profile-link-icon {
                width: 35px;
                height: 35px;
                margin-right: 15px;
            }

            &.current {
                background: darken(white, 10%);
            }

            span {
                text-transform: uppercase;
                font-size: 0.9em;
                font-weight: 500;
            }
        }
    }

    .profile-card {
        max-width: 500px;
    }

    .app-version {
        position: absolute;
        bottom: 5px;
        left: 5px;
        font-size: 0.6em;
    }
}

.play-search-result {
    display: flex;
    align-items: center;
    gap: 3px;

    img {
        width: 32px;
        height: 32px;
    }

    .play-tag-display {
        margin: 0;
    }
}

@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.playbook-page {
    position: relative;
    height: 100%;

    .play-editor-container,
    .fullscreen {
        height: 100%;
    }

    .create-play-popover {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: margin 0.5s ease-in;
        z-index: 1;
        left: 0;
        top: 0;
        display: none;

        & > .right-panel {
            background: $orange;
            color: white;
        }
    }

    .popover-enter {
        margin-left: -100%;
        display: flex;
    }

    .popover-enter.popover-enter-active {
        margin-left: 0;
    }

    .popover-exit {
        margin-left: 0;
        display: flex;
    }

    .popover-exit.popover-exit-active {
        margin-left: -100%;
    }

    .popover-enter-done {
        display: flex;
    }

    .ptr__children {
        display: flex;
        flex-direction: column;
    }

    @include media-breakpoint-down(lg) {
        .play-editor-container {
            .play-editor {
                .menu-title {
                    padding-left: 30px;
                }
            }

            .open-menu {
                width: 26px;
                height: 26px;
                display: block;
                position: absolute;
                z-index: 12;
                top: 10px;
                left: 10px;
                cursor: pointer;

                &:after {
                    content: '';
                    width: 18px;
                    height: 18px;
                    border-top: 3px solid black;
                    border-left: 3px solid black;
                    transform: rotate(-45deg);
                    position: absolute;
                    top: 5px;
                    left: 8px;
                }
            }
        }
    }
}

@import '@labradorsports/components/variables.scss';

.profile-image-editor {
    display: inline-block;

    .profile-pic {
        width: 150px;
        text-align: center;

        img {
            width: 150px;
            height: 150px;
            border-radius: 75px;
        }
    }

    a {
        font-size: 10px;
    }

    .profile-upload-link {
        font-size: 11px;
        text-transform: uppercase;
        text-decoration: underline;
        cursor: pointer;
    }

    #profile_image_upload {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .croppie-container .cr-viewport {
        border: 2px solid #ddd;
    }
}

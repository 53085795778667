.title-screen {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 6;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 2rem;
        display: flex;
        border-bottom: 2px solid;
        padding-bottom: 5px;
        margin-bottom: 15px;
        position: relative;

        .color-accent {
            border-bottom: 2px dashed;
            flex-grow: 1;
            margin-left: 1rem;
        }
    }

    .title-screen-contents {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        padding: 1rem;
        max-width: 500px;
        position: relative;
        justify-content: center;
    }

    .tag-icon {
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-left: 5px;
    }

    .title-screen-logo {
        width: 100px;
        height: 100px;
        margin: 0 auto;
        margin-bottom: 1rem;
        display: block;
    }

    .title-screen-description {
        overflow: auto;
    }
}

.goal-editor {
    label img {
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
    }

    button img {
        width: 40px;
        height: 40px;
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.ls-modal {
    width: 100%;

    h1 {
        img {
            width: 48px;
            height: 48px;
            margin-right: 1rem;
        }
    }

    .modal-panel {
        padding: 2rem;
    }

    .buttons-bar {
        padding: 2rem;
    }

    .modal-section {
        padding: 1rem 2rem;
        margin: 0 -2rem;
        background: #eee;
    }

    @include media-breakpoint-down(md) {
        .modal-panel {
            padding: 1rem;
        }

        .buttons-bar {
            padding: 1rem;
        }

        .modal-section {
            padding: 1rem;
            margin: 0 -1rem;
        }
    }
}

@import '@labradorsports/components/variables.scss';

.team-items-dropdown {
    padding: 0 1rem;
    max-width: 300px;
    text-align: left;

    .dropdown-play-name {
        flex-grow: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .share-audience {
        white-space: nowrap;
        align-items: center;
        justify-content: space-between;

        button {
            background: none;
            border: none;
            padding: 0;
        }
    }

    .MuiSwitch-colorSecondary:not(.Mui-checked) {
        .MuiSwitch-thumb {
            background-color: $primary-blue;
        }
    }

    a:not([href]):not([tabindex]).dropdown-menu-item {
        padding: 0.1rem 0;
    }

    hr {
        margin: 0.4rem 0;
    }

    .dummy-switch {
        display: inline-flex;
        width: 58px;
        height: 38px;
        overflow: hidden;
        padding: 12px;
        position: relative;
        flex-shrink: 0;
        z-index: 0;
        vertical-align: middle;

        &::after {
            content: '';
            height: 100%;
            width: 100%;
            border-radius: 7px;
            z-index: -1;
            background-color: #000;
            opacity: 0.38;
        }
    }
}

.play-tag-display {
    margin: 0.25rem;

    .tag-icon {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 5px;
    }
}

@import '@labradorsports/components/variables.scss';

.public-share-embed-footer {
    height: 30px;
    background: #f9f9f9;
    font-size: 0.8rem;
    cursor: pointer;
    line-height: 30px;
    z-index: 100;
    border-top: 1px solid #ccc;
    box-sizing: content-box;
    padding: 0 10px;
    color: $dark-blue;
    text-decoration: none;

    span {
        float: right;
    }

    .small-logo {
        width: 24px;
    }
}

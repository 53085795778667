.video-thumbnail {
    background-position: center center;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.25);
    }

    img {
        height: 60px;
        max-height: 100%;
        position: relative;
        z-index: 1;
    }
}

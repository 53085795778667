.play-preview {
    margin-right: 1rem;
    text-align: center;

    img {
        max-height: 200px;
    }

    .preview-placeholder {
        max-height: 100px;
        opacity: 0.8;
    }
}

@import '@labradorsports/components/variables.scss';

.app-login-page {
    background: $dark-blue;
    color: white;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .site-logo {
        height: 64px;
        flex: 0 0 auto;
    }

    .labsports-logo {
        display: block;
        height: 36px;
        margin: auto;
    }

    .signup-form,
    .login-form {
        max-width: 500px;
        display: inline-block;
    }

    .MuiOutlinedInput-root {
        background: transparent;
    }

    .MuiButton-root,
    .MuiInputLabel-outlined,
    .MuiInputLabel-outlined.Mui-focused,
    .MuiOutlinedInput-input,
    .MuiCheckbox-root,
    .MuiCheckbox-colorPrimary.Mui-checked {
        color: white;
    }

    .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-root.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
        border-color: white;
    }

    .MuiFormControl-marginNormal {
        margin-top: 8px;
    }

    .MuiButton-containedPrimary {
        background-color: $orange;
    }
}

.roster-search-result {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .profile-picture {
        width: 32px;
        height: 32px;
    }
}

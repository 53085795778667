@import '@labradorsports/components/variables.scss';

.content-viewer {
    position: relative;

    .ql-snow .ql-editor .play-link-pill.read-only {
        cursor: pointer;
        color: #06c;
        font-weight: 500;
        text-decoration: none;
    }

    .roster-player[data-added='true'] {
        font-weight: bold;
        cursor: pointer;
    }

    .personnel-tooltip {
        position: absolute;
        z-index: 800;

        .close-button {
            top: 0;
            right: 0;
        }

        .personnel-card {
            padding-right: 30px;
        }
    }
}

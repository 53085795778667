@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.event-calendar {
    .calendar-week {
        display: flex;
        border-bottom: 1px solid #ccc;

        .calendar-day.first-week {
            border-top: 1px solid #ccc;
        }

        &:last-child {
            border-bottom: none;

            .calendar-day {
                border-bottom: 1px solid #ccc;
            }
        }

        .calendar-day {
            flex: 0 0 14.3%;
            min-height: 130px;
            border-right: 1px solid #ccc;
            padding: 0.5rem;
            max-width: 14.3%;
            background: #fff;
            overflow-x: hidden;

            &.first-day {
                border-left: 1px solid #ccc;
            }

            &.placeholder {
                opacity: 0;
            }

            &.placeholder + :not(.placeholder) {
                border-left: 1px solid #ccc;
            }

            &.past {
                background: #eee;
            }

            &.today {
                box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);
                z-index: 1;
            }
        }
    }

    .calendar-date {
        text-align: right;
    }

    .calendar-header {
        display: flex;
    }

    .calendar-header-day {
        flex: 0 0 14.3%;
        text-align: right;
        padding-right: 0.5rem;
    }

    .change-month-button {
        min-width: auto;
        width: 50px;
        height: 50px;

        .arrow.prev {
            left: 20px;
        }

        .arrow.next {
            right: 20px;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-left: -0.75rem;
        margin-right: -0.75rem;

        .calendar-week .calendar-day {
            padding: 0;
            min-height: 60px;
        }

        .calendar-date {
            padding-right: 0.25rem;
            font-size: 80%;
        }

        .event-pill {
            padding: 0.1em;
            padding-left: 0.1rem;
            padding-top: 0.3rem;
            border-radius: 6px;

            &:before {
                left: 0;
                top: 0;
                width: 100%;
                height: 5px;
            }

            .event-pill-type {
                display: none;
            }

            .small {
                font-size: 65%;
            }
        }

        .calendar-header-day {
            padding-right: 0.25rem;
            font-size: 80%;
        }
    }
}

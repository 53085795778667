.roster-page {
    min-height: 100%;

    .page-content-panel {
        display: flex;
        flex-direction: column;
    }

    .remove-icon,
    .dropdown-button {
        cursor: pointer;
        width: 36px;
        height: 36px;
    }

    .dropdown-button-container {
        display: inline-block;
    }

    .empty-state {
        width: 100%;
        position: relative;
        pointer-events: all;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin: 3% 0;

        .roster-entry-options {
            margin-top: 1rem;
        }

        & > * {
            flex-grow: 0;
        }
    }

    .roster-entry-options {
        display: flex;
        width: 100%;
        max-width: 1000px;
        justify-content: space-around;
        flex-wrap: wrap;

        .roster-entry-option {
            width: 300px;
            background-color: white;
            display: flex;
            text-align: left;
            font-size: 0.8rem;
            border: 1px solid #ccc;
            border-radius: 6px;
            padding: 1rem;
            align-items: center;
            cursor: pointer;
            margin-bottom: 1rem;

            img {
                width: 60px;
                height: 60px;
                margin-right: 0.5rem;
            }
        }
    }

    .view-button {
        background: none;
        border: none;
        padding: 0;
        margin-right: 1rem;

        .active {
            font-weight: 500;
        }
    }

    .player-list {
        margin: 0 -0.5rem;
    }
}

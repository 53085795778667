.comment-editor {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    background: rgba(255, 255, 255, 0.85);
    border: 1px solid #333;

    .corners-svg {
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        position: absolute;
        top: -4px;
        left: -4px;
    }

    .quill-wrapper {
        height: 100%;
        width: 100%;
    }

    .quill {
        pointer-events: all;
        width: calc(100% - 1rem);
        height: calc(100% - 1rem);
        margin: 0.5rem;
    }

    .ql-container {
        border: none;
        font-size: 13px;
    }

    .ql-toolbar {
        pointer-events: all;
        position: absolute;
        bottom: calc(100% + 10px);
        background: #eee;
        border-radius: 18px;
        height: 36px;
        width: 270px;
        left: 50%;
        margin-left: -135px;
        padding: 4px;
        text-align: center;
    }

    .ql-editor {
        padding: 0;
    }
}

@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.player-attendance {
    // padding: 1rem;

    .summary {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    .summary-metric {
        text-align: center;
    }

    .metric-value {
        font-size: 4rem;
        line-height: 1;

        .small {
            font-size: 1rem;
        }
    }

    .MuiDataGrid-row:nth-child(even) {
        background-color: #f7f7f7;
    }

    @include media-breakpoint-down(sm) {
        .attendance-summary {
            flex-wrap: wrap;

            .summary-metric {
                margin: 1rem 0;
                flex-basis: 50%;
            }
        }
    }
}

@import '../../variables.scss';

.quill-wrapper {
    position: relative;

    .ql-container {
        font-family: inherit;
        font-size: 16px;
    }

    em {
        font-weight: inherit;
        font-style: italic;
    }

    &.readonly {
        .ql-toolbar {
            display: none;
        }

        .ql-container {
            border: none;
        }

        .ql-editor {
            padding: 0;
        }
    }
}

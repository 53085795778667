.inline-edit-field {
    button {
        border: none;
        outline: none;
        text-align: left;
        width: 100%;
        position: relative;
        cursor: default;

        .button-icon {
            margin: 0;
        }

        img {
            width: 20px;
            height: 20px;
            position: absolute;
            right: 1rem;
            top: 1rem;
            display: none;
        }
    }

    &:not(.disabled) button:hover {
        cursor: pointer;
        background: #eee;

        img {
            display: block;
        }
    }

    .field-value {
        display: inline-block;
        min-width: 50px;
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.community-search-results-container {
    position: absolute;
    z-index: 100;
    top: 100%;
    width: 400px;
    bottom: 100px;

    .community-search-results {
        max-height: 100%;
        overflow: auto;
        box-shadow: #ccc 0px 0px 5px 0px;
        background: white;
    }

    em {
        font-weight: 600;
    }

    .community-search-result {
        padding: 0.25rem;
        cursor: pointer;
        border-bottom: 1px solid #ccc;

        &:hover {
            background: #efefef;
        }
    }

    .empty-results {
        width: 50px;
        height: 50px;
        margin-right: 0.5rem;
    }

    @include media-breakpoint-down(sm) {
        width: calc(100% - 10px);
    }
}

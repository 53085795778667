@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '@labradorsports/components/variables.scss';

.plan-selector {
    display: flex;
    margin: 0 -10px 0;
    justify-content: space-around;

    .plan-summary {
        flex: 1 1 25%;
        margin: 5px;
        font-size: 0.9rem;
        position: relative;
        display: flex;
        flex-direction: column;
        transition: margin 0.1s linear;
        max-width: 350px;

        &.selected {
            margin-top: 0;
            margin-bottom: 10px;

            .summary-card {
                box-shadow: 1px 1px 6px -1px opacify($color: $dark-blue, $amount: 0.5);
                border: 1px solid $dark-blue;
            }
        }
    }

    .summary-flag {
        background: $orange;
        color: white;
        text-align: center;
        font-size: 1.1rem;
        padding: 0.25rem;

        .small {
            font-size: 70%;
        }
    }

    .summary-card {
        background: white;
        box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        border: 1px solid transparent;
    }

    p {
        margin-bottom: 0.6rem;
    }

    .plan-summary-section {
        border-top: 1px solid #ccc;
        padding: 0.5rem;
        margin-top: 1rem;
        flex: 1 1 auto;

        &.plan-header {
            text-align: center;
            margin-top: 0;
            flex: 0 0 auto;
            border-top: none;
        }

        h4 {
            margin-top: -1.9rem;
            margin-left: -0.5rem;
            margin-bottom: 0;

            span {
                font-size: 1.4rem;
                padding: 0.5rem;
                background: white;
                display: inline-block;
            }
        }
    }

    .plan-name {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1rem;
    }

    .plan-price {
        font-size: 1.2rem;
    }

    .plan-description {
        min-height: 60px;
        text-align: left;
    }

    .summary-flag,
    .flag-placeholder {
        flex: 0 0 50px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
    }

    &.plan-details-modal {
        max-width: 250px;
        margin: auto;
        text-align: left;

        .plan-description {
            min-height: auto;
        }
    }

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        justify-content: center;

        .plan-summary {
            flex: 1 1 40%;
            max-width: 250px;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-top: 0;

        .MuiFormControl-root {
            min-width: auto;
            width: 100%;
            max-width: 200px;
        }

        .plan-summary {
            flex: 1 1 80%;
            max-width: 250px;
            margin-top: 0;
            transition: none;
        }

        .plan-price {
            height: auto;
        }

        .flag-placeholder {
            flex: 0 0 0px;
        }

        &.selected {
            margin-top: 0;
            margin-bottom: 10px;
        }

        .plan-description {
            min-height: auto;
        }
    }
}

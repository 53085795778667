@import '@labradorsports/components/variables.scss';

.replay-slides {
    display: flex;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 6;
    background: #595959;
    color: white;
    align-items: center;
    padding: 0.5rem;
    font-size: 0.8rem;

    .replay-button {
        background: none;
        color: white;
    }
}

@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.play-editor {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .play-toolbar {
        display: block;
        flex: 0 0 auto;
        height: 48px;
        z-index: 11;
        padding: 9px;
        position: relative;
        background: white;
        border-bottom: 1px solid #ddd;

        .toolbar-button {
            min-width: auto;
            height: 32px;
            padding: 3px 4px;
            margin-left: 5px;

            &.glow {
                box-shadow: 0px 0px 3px 3px rgba($primary-blue, 0.4);
            }

            &.no-text img {
                margin-right: 0;
            }
        }

        .menu-title {
            font-size: 1.2em;
            vertical-align: middle;
            margin-left: 5px;
            font-weight: 500;

            .play-name {
                max-width: calc(100% - 450px);
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: 32px;
            }

            & > * {
                vertical-align: middle;
            }
        }
    }

    .field-toolbox {
        position: relative;
        display: flex;
        flex: 1 1 100%;
        background: white;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }

    .view-options-menu {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .debug-options-menu {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .fullscreen-button {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        background: rgba(255, 255, 255, 0.5);
        width: 60px;
        height: 60px;
        min-width: auto;
        border: 1px solid #ddd;

        .fullscreen-icon {
            width: 40px;
            height: 40px;
            margin: 0;
        }
    }

    .discrete-frame-timeline {
        z-index: 10;
    }

    .play-field-container {
        overflow: hidden;
        flex: 1 0 auto;
        position: relative;
    }

    &.split-panel-container .split-panel .right-panel {
        overflow: hidden;
    }

    .zoom-buttons {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 5;
    }

    &.read-only {
        .zoom-buttons {
            bottom: 35px;
        }

        .playback-speed {
            height: 60px;
        }
    }

    &.read-only.comment-viewer {
        .play-field {
            bottom: $frame-comment-display-height;
        }
    }

    .play-editor-menu {
        top: 10px;
        right: 10px;
        z-index: 7;
    }

    .clear-field-button,
    .save-formation-button {
        position: absolute;
        bottom: 10px;
        background: white;
        box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);

        &:hover {
            background: darken(white, 3%);
        }
    }

    .save-formation-button {
        right: 40px;
    }

    .clear-field-button {
        left: 10px;
    }

    @include media-breakpoint-down(md) {
        .play-toolbar {
            .menu-title {
                font-size: 1em;

                .play-name {
                    max-width: calc(100% - 200px);
                }
            }

            .toolbar-button {
                .player-icon,
                img {
                    margin-right: 0;
                }

                .toolbar-button-label {
                    display: none;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &.read-only.comment-viewer {
            .play-field {
                bottom: $frame-comment-display-height-mobile;
            }
        }

        &.designing-field {
            .toolbox {
                display: none;
            }
        }

        &.field-setup {
            .discrete-frame-timeline {
                display: none;
            }
        }
    }
}

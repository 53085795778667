.community-play-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;

    .community-play-card {
        margin: 0;

        .description .quill-wrapper .ql-container {
            max-height: 3rem;
        }
    }
}

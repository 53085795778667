.gif-download-modal {
    .rendered-gif-container {
        display: inline-block;
        width: 200px;
        height: 150px;
        margin: 0.5rem;
        padding: 0.5rem;
        box-shadow: #333 0px 0px 35px -15px;
    }

    .rendered-gif {
        max-width: 100%;
        max-height: 100%;
    }
}

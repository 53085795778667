.basic-text-editor {
    .ql-toolbar {
        background: #f7f7f7;
        border-radius: 4px 4px 0 0;
    }

    .ql-container {
        border-radius: 0 0 4px 4px;
        min-height: 100px;
    }
}

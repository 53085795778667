.scouting-report-display {
    .expand-caret {
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7.5px 0 7.5px 12px;
        border-color: transparent transparent transparent #888888;
        margin-right: 0.5rem;

        &.expanded {
            transform: rotate(90deg);
        }
    }
}

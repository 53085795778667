@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.select-plan-page {
    .MuiFormControl-root {
        min-width: 200px;
    }

    .plan-selector {
        margin-top: -50px;
        margin-bottom: 2rem;
    }

    .billing-frequency-switch {
        margin-bottom: 2rem;
    }

    @include media-breakpoint-down(sm) {
        .MuiFormControl-root {
            min-width: auto;
            width: 100%;
            max-width: 200px;
        }

        .plan-selector {
            margin-top: 0;
            margin-bottom: 0;
        }

        .billing-frequency-switch {
            margin-bottom: 1rem;
        }
    }
}

@import '@labradorsports/components/variables.scss';

.happening-now-event {
    @keyframes happening-now-glow {
        0% {
            box-shadow: 0px 0px 1px 1px rgba($red, 0.4);
        }

        80%,
        100% {
            box-shadow: 0px 0px 4px 4px rgba($red, 0.4);
        }
    }

    .happening-now-indicator {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: red;
        vertical-align: middle;
        margin-right: 5px;
        animation: happening-now-glow 0.7s infinite alternate;
    }
}

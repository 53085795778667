@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.player-overview {
    padding: 1rem;

    &.pending-updates {
        padding-bottom: $buttons-bar-height;
    }

    h5 {
        margin-top: 1rem;
    }

    .overview-body {
        display: flex;
        width: 100%;
    }

    .player-info {
        flex-grow: 1;
        flex-basis: 40%;
        margin-right: 1rem;
    }

    .player-activity {
        flex-grow: 1;
        flex-basis: 50%;
        position: relative;
    }

    .double-field {
        width: 100%;
        display: flex;

        & > * {
            flex-basis: 50%;
        }
    }

    .attendance-summary {
        flex-wrap: wrap;

        .summary-metric {
            margin: 1rem 0;
            flex-basis: 50%;
        }
    }

    .play-table {
        display: table;

        .header-row {
            font-weight: 500;
            font-size: 0.8rem;
        }

        .d-table-row {
            display: table-row;

            &:nth-child(even) {
                background-color: #f7f7f7;
            }
        }

        .d-table-cell {
            padding: 0.25rem 0;
            display: table-cell;
        }
    }

    .profile-upsell-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 1.5rem;
        background: rgba(255, 255, 255, 0.9);
        text-align: center;
    }

    .buttons-bar {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
    }

    .pending-contact-info {
        margin: 0 -1rem;
        padding: 1rem;
        background: #eee;
        position: relative;
    }

    @include media-breakpoint-down(md) {
        .overview-body {
            flex-wrap: wrap;
        }

        .player-info {
            margin-right: 0;
        }

        .player-activity {
            flex-grow: 1;
            flex-basis: 100%;
        }
    }
}

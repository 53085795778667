@import '@labradorsports/components/variables.scss';

.player-rating-form {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    padding-bottom: $buttons-bar-height;

    .rating-category {
        max-width: 700px;
    }
}

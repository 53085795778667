.player-editor {
    .player-editor-select {
        margin: 0;

        .MuiSelect-select {
            min-width: auto;
        }
    }

    .editor-divider {
        text-align: center;
        position: relative;

        hr {
            position: absolute;
            width: 100%;
            top: 12px;
            margin: 0;
        }

        span {
            display: inline-block;
            width: 40px;
            background: white;
            position: relative;
            z-index: 1;
        }
    }
}

.player-editor-select {
    .role-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        vertical-align: middle;
    }

    span.role-icon {
        font-size: 1.3em;
        color: #aaa;
        font-weight: bold;
        line-height: 20px;
        text-align: center;
    }

    .role-name {
        vertical-align: middle;
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.document-ack-details {
    .ack-details-title {
        display: flex;
        width: 100%;
        overflow: hidden;
        align-items: center;

        h4 {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .ack-details {
        display: table;
        width: 100%;

        .d-md-table-cell {
            padding: 0.25rem 0.5rem;
        }
    }

    .ack-details-header {
        font-weight: 500;
    }

    .ack-row {
        display: table-row;
    }

    .ack-row:nth-child(odd) {
        background: #eee;
    }

    .back-button {
        width: 26px;
        height: 26px;
        display: inline-block;
        cursor: pointer;
        position: relative;
        flex-shrink: 0;

        &:after {
            content: '';
            width: 18px;
            height: 18px;
            border-top: 3px solid black;
            border-left: 3px solid black;
            transform: rotate(-45deg);
            position: absolute;
            top: 5px;
            left: 8px;
        }
    }

    @include media-breakpoint-down(md) {
        .ack-row {
            display: flex;
            align-items: center;
        }

        .ack-details {
            flex-grow: 1;
        }
    }
}

@import '@labradorsports/components/variables.scss';

.billing-frequency-switch {
    position: relative;
    padding: 1px;

    .frequency-value {
        padding: 0.5rem 1.5rem;
        text-align: center;
        font-weight: 500;
        position: relative;
        z-index: 10;
        cursor: pointer;

        span {
            white-space: nowrap;
        }
    }

    .green {
        color: #02bda5;
        text-transform: uppercase;
    }

    .switch-background {
        margin: 0.5rem;
        background: #ddd;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .selected-pill {
        border: 1px solid $orange;
        background: white;
        position: absolute;
        height: 100%;
        width: calc(50% + 0.5rem);
        border-radius: 60px;
        top: 0;
        z-index: 1;
        transition: 0.05s left linear;
        left: 0;

        &.annual {
            left: calc(50% - 0.5rem);
        }
    }
}

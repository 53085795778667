@import '../../variables.scss';

.csv-import-modal {
    text-align: left;

    .import-icon {
        width: 64px;
        height: 64px;
    }

    .csv-import {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .csv-drop-zone {
        width: 100%;
        height: 300px;
        text-align: center;
        background: #eee;
        border: 1px dashed #ddd;
        border-radius: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .csv-icon {
            width: 120px;
            height: 120px;
            margin-bottom: 1rem;
        }
    }

    label {
        cursor: pointer;
        color: #07c;
        font-weight: 500;
    }

    .csv-file-selected {
        display: flex;
        align-items: center;
        background: #fefefe;
        border: 1px solid #ddd;
        padding: 1rem;
        border-radius: 1rem;

        .csv-icon {
            width: 80px;
            height: 80px;
            margin-right: 1rem;
        }
    }

    table {
        width: 100%;

        td {
            text-align: left;
            padding: 3px 5px;
        }

        .MuiFormControl-root {
            width: 100%;
        }

        .MuiSelect-select {
            min-width: 130px;
        }
    }
}

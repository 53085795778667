@import '@labradorsports/components/variables.scss';

.play-list-item {
    background: white;
    user-select: none;
    transition: transform 0.1s linear;

    .list-item-content {
        min-width: 0px;
    }

    &.dragging {
        transform: translate(-2px, -2px);
    }

    &.hovered-top {
        border-top: 2px solid $primary-blue;
    }

    &.hovered-middle {
        background: rgba($primary-blue, 0.2);
    }

    &.hovered-bottom {
        border-bottom: 2px solid $primary-blue;
    }
}

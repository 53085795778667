.pending-members-modal {
    .responsive-table .table-row:first-child .table-cell {
        background: transparent;
    }

    .thirds > * {
        flex-basis: 33.33%;
        flex-grow: 0;
    }

    .member-assign-row {
        position: relative;
        height: 80px;

        & > div {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.panel-header {
    display: flex;
    background: #eee;
    padding: 1rem;
    border-bottom: 1px solid #ccc;
    position: relative;
    box-shadow: inset 0px -3px 6px -4px rgba(0, 0, 0, 0.5);
    z-index: 2;
    background-size: 300% 100%;
    background-position-y: -200%;

    h4 {
        margin: 0;
    }

    &.has-back-button {
        padding-left: 2.5rem;
    }

    .back-button {
        width: 26px;
        height: 26px;
        display: block;
        z-index: 5;
        left: 10px;
        top: 50%;
        margin-top: -13px;
        cursor: pointer;
        position: absolute;

        &:after {
            content: '';
            width: 18px;
            height: 18px;
            border-top: 3px solid black;
            border-left: 3px solid black;
            transform: rotate(-45deg);
            position: absolute;
            top: 5px;
            left: 8px;
        }
    }

    .action-menu {
        position: absolute;
        bottom: -20px;
        right: 5px;
    }

    &.has-logo {
        min-height: 120px;
        background: none;
        padding-right: 0;
        flex: 0 0 auto;

        .logo-container:after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: linear-gradient(90deg, #ddd, rgba(255, 255, 255, 0));
            bottom: 0;
        }
    }

    .logo-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        overflow: hidden;

        .team-logo {
            position: absolute;
            right: 10px;
            top: 20px;
            max-width: 200px;
            opacity: 0.3;
        }
    }

    @include media-breakpoint-up(md) {
        &.full-page {
            background: #ddd;
            flex-wrap: nowrap;

            .logo-container {
                position: static;
                padding-right: 10px;

                &:after {
                    display: none;
                }

                .team-logo {
                    opacity: 1;
                    position: static;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        display: block;
    }
}

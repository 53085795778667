.social-icon {
    width: 40px;
    height: 40px;
    display: inline-block;

    img {
        width: 100%;
        height: 100%;
    }
}

@import '../../variables.scss';

.wizard-steps {
    display: flex;
    align-items: flex-end;
    width: 100%;

    .step {
        transition: max-width 0.3s ease-out, transform 0.2s linear 0.1s;
        transform: scaleX(1);
        max-width: 100%;
        flex: 1 1 auto;
        font-size: 80%;
        text-align: center;
        white-space: nowrap;

        img {
            display: none;
        }

        &:before {
            content: '';
            border-radius: 50%;
            background: white;
            display: block;
            margin: auto;
            border: 2px solid #aaa;
            width: 24px;
            height: 24px;
            margin-bottom: 6px;
        }

        &.complete {
            img {
                display: block;
                margin: auto;
                width: 36px;
                height: 36px;
            }

            &.active {
                img {
                    border: 2px solid $primary-blue;
                    border-radius: 50%;
                }
            }
        }

        &.active {
            font-weight: bold;

            &:before {
                width: 36px;
                height: 36px;
                margin-bottom: 0;
                border: 3px solid $dark-blue;
                box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);
            }
        }

        &.complete:before {
            content: none;
        }
    }

    .shrink-enter {
        transition: max-width 0.3s ease-in, transform 0.2s linear 0.1s;
        max-width: 0;
        transform: scaleX(0);
    }

    .shrink-enter.shrink-enter-active {
        max-width: 100%;
        transform: scaleX(1);
    }

    .shrink-exit {
        max-width: 100%;
        transform: scaleX(1);
    }

    .shrink-exit.shrink-exit-active {
        max-width: 0;
        transform: scaleX(0);
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.formation-summary {
    position: absolute;
    bottom: 100%;
    width: 500px;
    max-height: 600px;
    display: flex;
    background: white;
    box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);
    z-index: 12;
    color: rgba(0, 0, 0, 0.87);
    padding: 0.5rem;
    left: calc(50% - 200px);

    .frame-preview {
        border: 1px solid #ccc;
        border-radius: 6px;
        width: 270px;
        height: 200px;
    }

    .formation-details {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
    }

    @include media-breakpoint-down(sm) {
        left: 0;
        width: 100%;
        flex-direction: column;
        height: auto;
    }
}

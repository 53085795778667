.button-menu {
    background: white;
    border-radius: 4px;
    box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);
    position: absolute;

    img {
        width: 28px;
        height: 28px;
        margin-right: 4px;
    }

    .MuiButton-root {
        display: flex;
        justify-content: start;
    }
}

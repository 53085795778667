@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.edit-practice-plan-section {
    &.dragging {
        opacity: 0.6;
    }

    .move-arrows {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
    }

    .move-up-arrow,
    .move-down-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        cursor: pointer;

        &.disabled {
            cursor: default;
        }
    }

    .move-up-arrow {
        border-width: 0 20px 20px 20px;
        border-color: transparent transparent #bbb transparent;
        margin-bottom: 0.5rem;

        &:hover {
            border-bottom-color: #aaa;
        }

        &.disabled {
            border-bottom-color: #ddd;
        }
    }

    .move-down-arrow {
        border-width: 20px 20px 0 20px;
        border-color: #bbb transparent transparent transparent;

        &:hover {
            border-top-color: #aaa;
        }

        &.disabled {
            border-top-color: #ddd;
        }
    }

    .content-editor {
        cursor: default;
    }

    .handle {
        position: absolute;
        cursor: move;
        width: 24px;
        height: 24px;
        left: 1rem;
        top: 1rem;

        &:after,
        &:before {
            position: absolute;
            content: ' ';
            width: 24px;
            height: 16px;
            border: 1px solid #ccc;
            border-right: none;
            border-left: none;
            top: 4px;
            left: 0;
        }

        &:after {
            top: 12px;
            border-bottom: none;
        }
    }

    @include media-breakpoint-down(md) {
        .MuiFormControl-root {
            width: 100%;
        }
    }
}

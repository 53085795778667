.contextual-actions {
    position: absolute;
    bottom: 1rem;
    z-index: 11;
    display: flex;
    width: 100%;
    justify-content: center;

    .card {
        padding: 1rem;
        border-radius: 1rem;

        .d-sm-flex {
            gap: 1rem;
        }
    }
}

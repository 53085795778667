.ball-flag {
    transition: transform 0.7s linear;
    pointer-events: none;

    &.faded {
        opacity: 0.35;
    }

    &.clickable {
        pointer-events: all;
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '@labradorsports/components/variables.scss';

$frame-buttons-width: 180px;

.frame-timeline {
    height: $frame-timeline-height;
    background: #f0f0f0;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.5);
    position: relative;
    flex: 0 0 auto;
    z-index: 7;

    .frame-list-container {
        height: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        margin-left: $frame-buttons-width;
    }

    .frame-list {
        height: 100%;
        display: flex;
        padding: 0 20px;
        float: left;
        align-items: center;

        & > * {
            margin: 5px;
            flex-shrink: 0;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 5px;
        }
    }

    .frame-like {
        width: 90px;
        height: 60px;
        cursor: pointer;
        text-align: center;

        &.active {
            box-shadow: 1px 1px 6px -1px transparentize($color: $dark-blue, $amount: 0.5);
        }
    }

    .add-video-button {
        .add-video-icon {
            height: 100%;
        }

        .video-thumbnail {
            height: 100%;
            width: 100%;
        }
    }

    .title-screen-button {
        width: 90px;
        height: 60px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 45px;
            height: 45px;
        }
    }

    .frame-buttons {
        display: inline-block;
        position: absolute;
        height: 100%;
        width: $frame-buttons-width;
        line-height: 100px;
        background: white;
        box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.5);
        z-index: 2;

        .frame-button {
            height: 80px;
            min-width: 80px;
            padding: 0;
            flex-direction: column;

            .btn-label {
                display: block;
                margin-top: 10px;
                line-height: normal;
            }
        }

        .play-buttons {
            clear: both;
            text-align: center;

            .MuiButton-root {
                img {
                    width: 40px;
                    height: 40px;
                    margin: 0;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        height: $frame-timeline-height-mobile;

        .frame-list-container {
            margin-left: 110px;
        }

        .add-video-button {
            width: 70px;
            height: 45px;
        }

        .frame-buttons {
            width: 110px;
            line-height: 50px;

            .frame-button {
                height: 50px;
                min-width: 50px;
                width: 50px;

                .btn-label {
                    display: none;
                }
            }

            .play-buttons {
                margin-top: 5px;
            }
        }
    }
}

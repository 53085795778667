.post-feed {
    margin: auto;
    min-height: 300px;

    .post-display-container {
        position: relative;
        min-height: 300px;
    }

    .no-posts-state {
        text-align: center;
        margin: 50px;

        img {
            width: 150px;
            height: 150px;
        }
    }
}

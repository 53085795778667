.field-svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .clickable {
        cursor: pointer;
    }

    .fade-enter {
        opacity: 0;
    }

    .fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity 0.2s ease-in;
    }

    .fade-exit {
        opacity: 1;
    }

    .fade-exit.fade-exit-active {
        opacity: 0;
        transition: opacity 0.2s ease-in;
    }

    &.fast-playback {
        .fade-enter.fade-enter-active {
            transition: none;
        }

        .fade-exit.fade-exit-active {
            transition: none;
        }
    }

    .move-preview {
        transition: transform 0.1s linear;
    }

    &.setup {
        .player {
            transition: none;
        }

        .ball-flag {
            transition: none;
        }

        &.placing-ball .ball-flag {
            transition: transform 0.1s linear;
        }
    }

    &.disable-animation {
        * {
            transition: none;
        }
    }

    text {
        pointer-events: none;
        user-select: none;
    }

    image.team-logo {
        opacity: 0.15;
    }
}

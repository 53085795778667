@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.schedule-builder-row {
    border-bottom: 1px solid #ccc;
    padding: 0.5rem 0;

    .builder-event-details {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        .recurring-checkbox {
            margin-right: 5px;

            .MuiFormControlLabel-label {
                display: none;
            }
        }

        & > .d-flex {
            flex-wrap: wrap;

            & > .d-flex {
                flex-shrink: 1;
                flex-grow: 1;
                flex-wrap: wrap;

                &:first-child {
                    flex-basis: 400px;
                }

                & > * {
                    margin-right: 10px;
                }
            }
        }

        .event-type {
            flex-grow: 1;
            flex-basis: 150px;
            flex-shrink: 0;
        }

        .event-date {
            flex-grow: 1;
            flex-basis: 150px;
            flex-shrink: 0;
            margin-top: 8px;
            margin-bottom: 8px;
        }

        .time-range-input {
            flex-grow: 1;
            flex-basis: 40%;
        }

        .place-search-box {
            flex-grow: 1;
            flex-basis: 40%;
        }
    }

    @include media-breakpoint-down(sm) {
        .builder-event-details {
            flex-wrap: wrap;

            .recurring-checkbox {
                order: 1;
                flex-basis: 50%;
                margin: 0;
                text-align: center;

                .MuiFormControlLabel-label {
                    display: block;
                }
            }

            button {
                order: 2;
                margin: 0;
                flex-basis: 50%;
            }

            .builder-event-form {
                order: 3;
            }
        }
    }
}

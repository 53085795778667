@import '../../variables.scss';

.play-video-playback {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        color: white;
        font-size: 2rem;
    }

    .video-wrapper {
        max-width: 992px;
        width: 100%;
    }

    .iframe-wrapper {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        overflow: hidden;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media screen and (min-aspect-ratio: 16/9) and (max-height: 600px) {
        .video-wrapper {
            height: 100%;
        }

        .iframe-wrapper {
            height: 100%;
            width: 100%;
            padding-bottom: 0;
        }
    }
}

.app-container {
    .play-video-playback {
        top: $app-chrome-header-height;
        bottom: $app-chrome-nav-height;
    }
}

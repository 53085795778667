@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.account-info-page {
    .card,
    .alert {
        max-width: 900px;
        margin: auto;
    }

    .basic-details {
        width: 100%;
        align-items: center;

        & > .d-sm-flex {
            flex-wrap: wrap;
            flex: 1 1 auto;

            @include media-breakpoint-up(sm) {
                & > * {
                    width: 50%;
                    padding-right: 10px;
                }
            }
        }
    }

    .edit-button {
        flex: 0 0 20%;
    }
}

@import '@labradorsports/components/variables.scss';

.confirm-event-details {
    text-align: left;

    .section {
        padding: 0.5rem 0;
    }

    .small .small {
        font-size: 100%;
    }

    .event-date {
        flex-grow: 1;

        b {
            text-transform: uppercase;
        }
    }

    .event-type {
        color: var(--event-color);
        font-weight: 500;
    }

    .game-team {
        font-weight: 500;
    }
}

.choose-drill-type {
    background: #555;
    color: white;
    height: 100%;

    .field-type-button {
        width: 150px;
        margin-bottom: 10px;
        color: #333;
    }

    .back-button {
        color: #eee;
    }
}

@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.edit-person-flyover {
    height: 100%;
    display: flex;
    flex-direction: column;

    .form-header {
        padding: 1rem;
        background: linear-gradient(90deg, $dark-blue 0%, $orange 100%);
        color: white;

        h5 {
            margin: 0;
        }

        button {
            background: none;
            border: none;
            font-size: 2rem;
            position: absolute;
            color: white;
            top: 1rem;
            right: 1rem;
            width: 1.25rem;
            height: 1.25rem;
            line-height: 1.25rem;
            text-align: center;
        }
    }

    .form-body {
        padding: 1rem;
        flex-grow: 1;
    }
}

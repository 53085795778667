@import '@labradorsports/play-rendering/main.scss';
@import '@labradorsports/components/variables.scss';
@import '@labradorsports/components/main.scss';
@import '@labradorsports/ui/main.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~croppie/croppie.css';
@import '~rc-slider/assets/index.css';
@import '~react-quill/dist/quill.snow.css';

#react {
    height: 100%;
}

body {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    position: fixed;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
}

h1 {
    font-size: 2rem;
}

p {
    margin-bottom: 0.5rem;
}

em {
    font-weight: 500;
    font-style: normal;
}

.small {
    font-size: 80%;
}

.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}

.alert-warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}

.alert-success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
}

.app-container .header-bar {
    z-index: 1001;
}

.body {
    height: 100%;
    overflow-x: hidden;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: $header-height;
    background-color: $background;

    &.no-chrome {
        padding-top: 0;
    }

    @include media-breakpoint-down(md) {
        padding-top: $short-header-height;

        &.no-chrome {
            padding-top: 0;
        }
    }

    &.app-body {
        padding-top: $short-header-height;
        padding-bottom: $app-chrome-nav-height;

        &.no-chrome {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.section {
    padding: 0.5rem;
    border-bottom: 1px solid #ddd;

    &:last-child {
        border-bottom: none;
    }

    &.even {
        background-color: #eee;
    }
}

.error-text {
    color: red;
    font-size: 12px;
}

a {
    color: #07c;

    &:hover {
        color: #0056b3;
    }
}

a:not([href]):not([tabindex]) {
    color: #07c;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: #0056b3;
        text-decoration: underline;
    }
}

ul {
    padding: 0;
    margin: 0;

    li {
        list-style-type: none;
    }
}

textarea {
    width: 100%;
    resize: none;
    min-height: 100px;
}

.react-datepicker-popper {
    z-index: 10;
}

button[disabled] img {
    opacity: 0.4;
}

button {
    background: none;
    border: none;
    color: rgba(0, 0, 0, 0.87);
}

.responsive-table {
    display: table;
    width: 100%;

    .MuiFormControlLabel-root {
        margin-right: 0;
    }

    .table-row {
        display: table-row;

        .table-cell {
            display: table-cell;
            vertical-align: middle;
            padding: 10px;

            .table-cell-label {
                display: none;
            }

            &.no-padding {
                padding: 0;
            }

            &.sortable {
                cursor: pointer;
            }
        }

        &.less-pad {
            .table-cell {
                padding: 5px;
            }
        }

        &:nth-child(odd) {
            .table-cell {
                background: #f0f0f0;
            }
        }

        &.header-row {
            .table-cell {
                background: $primary-blue;
                color: white;
                font-weight: normal;

                .table-cell-label {
                    display: block;
                }
            }
        }
    }

    .table-row-group {
        display: table-row-group;
    }

    .sort-buttons {
        position: relative;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
        }

        &:before {
            top: 0;
            border-width: 0 5px 10px 5px;
            border-color: transparent transparent #ffffff transparent;
        }

        &:after {
            bottom: 0;
            border-width: 10px 5px 0 5px;
            border-color: #ffffff transparent transparent transparent;
        }
    }
}

.card {
    background: white;
    box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);
    border-radius: 0;
    border: none;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;

    &.round {
        padding: 1rem;
        border-radius: 1.5rem;
        box-shadow: none;
        border: 1px solid #ddd;
    }

    &.no-padding {
        padding: 0;
    }
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 2rem;
    font-weight: lighter;
    width: 30px;
    line-height: 30px;
    cursor: pointer;
    text-align: center;
}

.close {
    cursor: pointer;
}

.clickable {
    cursor: pointer;
}

.panel-tabs {
    box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 3;
    background: white;
}

.MuiButton-root img,
.button-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 5px;
}

.MuiButton-root.confirm-button {
    color: white;
    background: $green;

    &:hover {
        background: darken($green, 7%);
    }
}

$arrow-size: 18px;

.arrow {
    background: none;
    border: none;
    width: $arrow-size;
    height: $arrow-size;
    border-top: 3px solid #333;
    border-right: 3px solid #333;
    position: absolute;
    top: 50%;
    margin-top: calc($arrow-size / -2);
    padding: 0;

    &[disabled] {
        border-color: #ccc;
    }

    &.prev {
        transform: rotate(-135deg);
        left: 10px;
    }

    &.next {
        transform: rotate(45deg);
        right: 10px;
    }
}

.event-type-game {
    --event-color: #{$orange};
}

.event-type-practice {
    --event-color: #{$primary-blue};
}

.event-type-scrimmage {
    --event-color: #{lighten($orange, 20%)};
}

.event-type-none {
    --event-color: #ccc;
}

.checkbox-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.list-item {
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    align-items: stretch;
    min-height: 60px;

    &:hover {
        background: #f7f7f7;
    }

    &.active {
        background: #f0f0f0;
    }

    .dropdown-menu-container {
        margin-left: 0.25rem;
    }

    .list-item-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        padding: 10px 5px 10px 10px;
    }

    .checkbox-wrapper {
        padding-left: 10px;
        margin-right: -10px;

        .MuiFormControlLabel-root {
            margin-right: 0;
        }
    }
}

@include media-breakpoint-down(md) {
    .responsive-table {
        display: block;

        .table-row {
            display: block;
            background: white;
            margin-bottom: 15px;
            box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);
            padding: 3px;

            &:nth-child(odd) {
                .table-cell {
                    background: none;
                }
            }

            .table-cell {
                display: block;
                padding: 7px;

                .table-cell-label {
                    font-weight: 500;
                    display: inline-block;
                    width: 50%;
                }
            }

            &.header-row {
                display: none;
            }
        }

        .table-row-group {
            display: block;
        }
    }
}

@include media-breakpoint-down(sm) {
    .responsive-table {
        .table-row {
            .table-cell {
                .table-cell-label {
                    width: 100%;
                }
            }
        }
    }
}

.app-container {
    .MuiSnackbar-anchorOriginBottomLeft {
        bottom: calc($app-chrome-nav-height + 24px);

        @include media-breakpoint-down(sm) {
            bottom: calc($app-chrome-nav-height + 8px);
        }
    }
}

.no-wrap {
    white-space: nowrap;
}

.row.less-pad {
    margin-left: -5px;
    margin-right: -5px;

    & > * {
        padding: 5px;
    }
}

.strikethrough {
    text-decoration: line-through;
}

.button-link {
    background: none;
    border: none;
    text-decoration: underline;
    color: #07c;

    &:hover {
        color: #0056b3;
    }
}

.full-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.empty-state {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    flex-direction: column;
    text-align: center;
    padding: 1rem;

    img {
        width: 150px;
        height: 150px;
    }
}

.rounded-button.MuiButton-root {
    border-radius: 18px;
}

.grecaptcha-badge {
    visibility: hidden;
}

a.MuiButton-root {
    &:hover {
        color: $primary-blue;
    }
}

a.MuiButton-containedSecondary {
    &:hover {
        color: #fff;
    }
}

.page-content-panel {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto 1rem auto;
    padding: 1rem;

    @include media-breakpoint-down(sm) {
        padding: 0.5rem;
        --bs-gutter-x: 1rem;
    }
}

.field-header {
    font-size: 80%;
    text-transform: uppercase;
    color: #777;
}

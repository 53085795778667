@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.billing-item-card {
    max-width: 800px;
    margin: 10px auto;

    .team-card-section {
        width: 100%;

        & > * {
            flex: 1 1 auto;

            &:last-child {
                flex: 0 0 auto;
                align-self: flex-start;
            }
        }
    }

    .team-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        .team-row-name {
            flex: 1 1 20%;
        }

        .team-row-membership {
            flex: 1 1 30%;
        }

        .team-row-actions {
            flex: 1 1 50%;
            text-align: right;
        }
    }

    .team-logo,
    .logo-placeholder {
        width: 150px;
        height: 150px;
        display: block;
    }

    .team-colors {
        float: right;
    }

    .color-swatch {
        display: inline-block;
        border: 3px solid #ccc;
        border-radius: 1px;
        width: 35px;
        height: 35px;
        margin: 0 0.25rem;
    }

    .logo-placeholder {
        background: #eee;
    }

    @include media-breakpoint-down(sm) {
        .team-logo,
        .logo-placeholder {
            width: 100px;
            height: 100px;
        }

        .team-row {
            flex-wrap: wrap;

            .team-row-actions,
            .team-row-membership {
                flex: 1 1 100%;
            }

            .MuiButton-root {
                padding: 6px 0;
            }
        }
    }
}

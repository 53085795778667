@import '../../variables.scss';

.video-playback {
    max-width: 992px;
    width: 100%;

    .iframe-wrapper {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        overflow: hidden;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media screen and (min-aspect-ratio: 16/9) and (max-height: 600px) {
        height: 100%;

        .iframe-wrapper {
            height: 100%;
            width: 100%;
            padding-bottom: 0;
        }
    }
}

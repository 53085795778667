@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

$frame-comment-animation-length: 30px;

.frame-comment-viewer {
    position: absolute;
    display: flex;
    width: 100%;
    background: white;
    padding: 0;
    z-index: 6;
    align-items: center;
    height: $frame-comment-display-height;
    bottom: 0;
    box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.5);

    $arrow-space: 30px;

    &.collapsed {
        height: 0;
        bottom: 0;
    }

    .collapse-button {
        position: absolute;
        bottom: 100%;
        background: white;
        padding: 2px 10px;
        right: 10px;
        border: 1px solid #ccc;
        cursor: pointer;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    .comment-text {
        padding: 5px $arrow-space 5px 0;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        flex: 1 1 auto;
    }

    .comment-count {
        text-align: center;
        display: inline-block;
        padding: 0 10px 0 $arrow-space;

        img {
            display: block;
            width: 30px;
            height: 30px;
        }
    }

    .frame-comment-enter,
    .frame-comment-exit {
        transition: margin 350ms ease-out, opacity 350ms ease-out, left 350ms ease-out, right 350ms ease-out;
    }

    .frame-comment-enter {
        opacity: 0;
        margin-left: $frame-comment-animation-length;
        margin-right: -$frame-comment-animation-length;
    }

    .frame-comment-enter.frame-comment-enter-active {
        opacity: 1;
        margin-left: 0;
        margin-right: 0;
    }

    .frame-comment-exit {
        position: absolute;
        opacity: 1;
        left: 0;
        right: 0;
        top: 5px;
    }

    .frame-comment-exit.frame-comment-exit-active {
        opacity: 0;
        left: -$frame-comment-animation-length;
        right: $frame-comment-animation-length;
    }

    @include media-breakpoint-down(sm) {
        height: $frame-comment-display-height-mobile;
        font-size: 0.8rem;
    }
}

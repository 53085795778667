@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.app-navbar {
    height: calc($app-chrome-nav-height + 2px);
    position: fixed;
    width: 100%;
    bottom: 0;
    border-bottom: $app-bottom solid $dark-blue;
    display: flex;
    z-index: 1001;
    justify-content: center;
    overflow: hidden;

    .nav-link-bar {
        width: 100%;
        background: $orange;
        position: absolute;
        top: -1px;
        left: 0;
        height: 4px;
        transition: transform 0.1s linear;
        transform-origin: center center;
        transform: scaleX(0);
        box-shadow: 0 0 50px 15px rgba(255, 255, 255, 0.5);
        z-index: 1;
    }

    .nav-background {
        background: $dark-blue;
        width: 100%;
        top: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .nav-link {
        color: white;
        font-size: 14px;
        text-align: center;
        position: relative;
        flex: 1 1 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 100px;

        img {
            display: inline-block;
            width: 35px;
            height: 35px;
            margin: 0.5rem 0 0.2rem;
        }

        &.active {
            .nav-link-bar {
                transform: scaleX(1);
            }
        }
    }
}

@import '@labradorsports/components/variables.scss';

.opponent-stats-table {
    .stat-name {
        min-width: 150px;
    }

    .MuiOutlinedInput-root {
        background: none;
    }

    .W {
        background: transparentize($green, 0.8);

        .row-result {
            color: $green;
        }
    }

    .L {
        background: transparentize($red, 0.8);

        .row-result {
            color: $red;
        }
    }
}

.field-comment {
    cursor: pointer;

    &.selected {
        opacity: 0;
    }

    .quill-wrapper {
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        background: rgba(255, 255, 255, 0.85);

        .quill {
            width: 100%;
            height: 100%;
        }

        .ql-container {
            border: none;
            font-size: 13px;
        }
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '@labradorsports/components/variables.scss';

.create-team-wizard {
    flex: 1 0 auto;

    .MuiSelect-select {
        min-width: 100px;
    }
}

@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.scouting-report-page,
.create-scouting-report-page {
    @include media-breakpoint-down(sm) {
        .panel-content {
            padding: 0.5rem;
        }
    }
}

.opponent-name {
    font-weight: 500;
}

.field-header {
    font-size: 80%;
    text-transform: uppercase;
    color: #777;
}

.stats-table-container {
    max-height: 768px;
    overflow: auto;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.stats-table {
    min-width: 100%;
    margin-right: -1px;

    th {
        background: $primary-blue;
        color: white;
        font-weight: normal;
        padding: 10px;
        text-align: center;
    }

    td {
        padding: 3px 8px;
        border: 1px solid #ddd;

        .data-value {
            padding: 0.5rem;
            min-width: 100px;
            min-height: 34px;
        }

        &:first-child {
            border-left: none;
        }

        &:last-child {
            border-right: none;
        }

        .MuiTextField-root {
            width: 100%;
            min-width: 100px;

            input {
                padding: 0.5rem;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border: none;
        }

        &.header-column {
            background: #f0f0f0;
        }
    }
}

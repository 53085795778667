.community-play-comments {
    p {
        margin-bottom: 0.5rem;
    }

    .community-play-comment {
        border-bottom: 1px solid #ccc;
        padding: 0.5rem;
    }

    .divider {
        display: inline-block;
        padding: 0 10px;
    }

    .profile-link {
        display: inline-flex;
        align-items: center;
    }

    .profile-picture {
        width: 36px;
        height: 36px;
        margin-right: 0.5rem;
    }

    .pending {
        opacity: 0.7;

        .comment-pending {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
}

.play-viewer-details {
    .panel-content {
        padding: 0;
    }

    .viewer-item {
        border-bottom: 1px solid #ddd;
        padding: 10px;
    }

    .viewer-name {
        font-size: 1.2em;
        line-height: 1.6em;
    }

    .last-viewed {
        font-size: 0.8em;
        color: #aaa;
    }

    .view-count {
        float: right;
        line-height: 1.6em;
    }
}

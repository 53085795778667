@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.global-search-box {
    display: flex;
    max-width: calc(36px + 1rem);
    transition: max-width 0.15s ease-in;
    position: absolute;
    pointer-events: none;
    height: $short-header-height;
    background: white;

    .search-container {
        width: 100%;
        opacity: 0;
        transition: opacity 0.15s ease-in;
    }

    .search-button {
        position: absolute;
        top: calc(50% - 14px);
        left: 8px;
        z-index: 1;
        opacity: 1;
        transition: opacity 0.1s ease-in;
    }

    &.shown {
        max-width: 400px;
        pointer-events: all;

        .search-container {
            opacity: 1;
        }

        .search-button {
            opacity: 0;
        }
    }

    @include media-breakpoint-down(md) {
        z-index: 1;
        position: fixed;
        left: calc(100% - 140px);
        right: 70px;
        top: -$header-height;
        transition: top 0.05s ease-in 0.1s, left 0.15s ease-in, right 0.15s ease-in;
        max-width: 100%;

        &.shown {
            position: fixed;
            top: 0;
            left: calc(100% - 360px);
            max-width: 100%;
            transition: top 0.05s ease-in, left 0.15s ease-in, right 0.15s ease-in;
        }

        .MuiFormControl-root {
            width: 100%;
        }
    }

    @include media-breakpoint-down(sm) {
        &.shown {
            left: 0;
            right: 0;
        }
    }
}

.app-container {
    .global-search-box {
        background-color: $dark-blue;
        z-index: 1;
        position: fixed;
        left: calc(100% - 140px);
        right: 70px;
        top: -$header-height;
        transition: top 0.05s ease-in 0.1s, left 0.15s ease-in, right 0.15s ease-in;
        max-width: 100%;

        &.shown {
            position: fixed;
            top: 0;
            left: calc(100% - 360px);
            max-width: 100%;
            transition: top 0.05s ease-in, left 0.15s ease-in, right 0.15s ease-in;
        }

        .MuiFormControl-root {
            width: 100%;
        }

        .MuiFormLabel-root {
            color: white;
        }

        .MuiInputBase-root {
            background-color: $dark-blue;
            color: white;
        }

        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border: 1px solid white;
        }

        @include media-breakpoint-down(sm) {
            &.shown {
                left: 0;
                right: 0;
            }
        }
    }
}

@import '@labradorsports/components/variables.scss';

.opponent-schedule-table {
    .stats-table {
        min-width: 100%;

        .MuiSelect-select {
            min-width: 150px;
        }

        .MuiFormControl-root {
            min-width: 100px;
        }

        .MuiInputBase-adornedEnd {
            min-width: 160px;

            .MuiInputAdornment-root {
                margin-left: 0;
            }
        }

        .W {
            background: transparentize($green, 0.8);

            .row-result {
                color: $green;
            }
        }

        .L {
            background: transparentize($red, 0.8);

            .row-result {
                color: $red;
            }
        }

        .row-result {
            text-align: center;
            min-width: 30px;
            font-weight: bold;
        }

        td {
            min-width: 100px;

            .MuiOutlinedInput-root {
                width: 100%;

                input {
                    padding: 18.5px 14px;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: rgba(0, 0, 0, 0.23);
            }
        }
    }
}

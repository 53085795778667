.movement-move-editor-menu {
    img {
        width: 28px;
        height: 28px;
        margin: 0;
    }

    .d-flex {
        justify-content: center;
    }

    .move-type-option {
        width: 50px;
        height: 50px;
        margin: 3px;
        min-width: auto;

        &.Mui-disabled {
            background: #e0e0e0;
        }

        img {
            width: 34px;
            height: 34px;
            margin: 0;
        }
    }
}

@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.teams-menu {
    display: inline-block;
    border-left: 1px solid #ddd;
    position: relative;
    min-width: 200px;
    border-right: 1px solid #ddd;

    .team-option {
        height: 35px;
        line-height: 35px;
        padding: 0 10px;
        cursor: pointer;
        display: flex;
        width: 100%;
        position: relative;

        &:hover {
            background: #fcfcfc;
        }

        &.unpaid {
            .team-name {
                color: $red;
            }
        }

        &.pending {
            .team-name {
                color: darken($yellow, 15%);
            }
        }
    }

    .team-name {
        font-weight: bold;
        margin-right: 5px;
        vertical-align: middle;
        overflow: hidden;
        max-width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        flex-grow: 1;
    }

    .team-code {
        vertical-align: middle;
        flex-grow: 0;
    }

    .drop-down {
        background: #f0f0f0;
        overflow: hidden;
        box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 100%;

        .menu-separator {
            border-bottom: 1px solid #ddd;
        }

        &.hidden {
            height: 0;
        }
    }

    .check-icon {
        flex-grow: 0;
        width: 25px;
        position: absolute;
        left: 5px;
        top: 5px;
    }

    .join-team {
        .join-button {
            display: block;
            width: 100%;
        }
    }

    .header-menu-button {
        width: 100%;
    }
}

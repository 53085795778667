@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.tool-wrapper {
    display: inline-block;
}

.tool {
    display: inline-block;
    width: 70px;
    min-height: 70px;
    text-align: center;
    padding: 5px;
    font-size: 0.8em;

    .player-icon,
    .line-icon {
        margin: 6px;
    }

    img {
        width: 40px;
        height: 40px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 0.8em;
        width: auto;
        min-width: 65px;
        height: 60px;
        min-height: unset;

        .player-icon,
        .line-icon {
            margin: 3px;
        }

        img {
            width: 30px;
            height: 30px;
        }
    }

    &.disabled {
        opacity: 0.6;
    }

    &:not(.disabled) {
        cursor: pointer;

        &:hover {
            background: darken(white, 5%);
        }
    }
}

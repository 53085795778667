.college-search {
    .MuiAutocomplete-listbox {
        span {
            background: white;
            width: 30px;
            height: 30px;
            display: inline-block;
            text-align: center;
            margin-right: 0.5rem;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.rsvp-toggle {
    .rsvp-buttons {
        & :nth-child(1) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        & :nth-child(2) {
            border-radius: 0;
            border-left: none;
            border-right: none;
        }

        & :nth-child(3) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .rsvp-select {
        min-width: 120px;
        margin-top: 0;
        vertical-align: middle;

        .MuiSelect-select {
            min-width: auto;
        }
    }
}

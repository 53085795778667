.place-search-box {
    position: relative;

    .favorite-places {
        position: absolute;
        background: white;
        width: 100%;
        z-index: 1000;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
        top: 66px;
        font-size: 11px;
        border-radius: 2px;
    }

    .favorite-place {
        cursor: default;
        padding: 0 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 30px;
        text-align: left;
        border-top: 1px solid #e6e6e6;
        font-size: 11px;
        color: #515151;
        line-height: 30px;

        &:hover {
            background: #fafafa;
        }
    }

    .place-icon {
        display: inline-block;
        width: 20px;
        height: 15px;
        background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png);
        background-position: -1px -161px;
        background-size: 34px;
        margin-top: 6px;
    }
}

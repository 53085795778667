@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '@labradorsports/components/variables.scss';

.play-list {
    padding: 0;
    min-height: 100%;

    .tag-filter {
        padding: 10px;
        font-size: 0.8rem;
        border-bottom: 1px solid #ddd;
    }

    .tag-filter-item {
        margin-right: 12px;
    }

    .tags-filtered {
        cursor: pointer;
    }

    .hide-filter {
        display: block;
        position: relative;
        z-index: 1;
    }

    .empty-filter {
        padding: 10px;
    }

    .coach-only-note {
        margin-left: 2rem;
    }

    .back-button {
        width: 26px;
        height: 26px;
        display: inline-block;
        z-index: 5;
        cursor: pointer;
        position: relative;

        &:after {
            content: '';
            width: 18px;
            height: 18px;
            border-top: 3px solid black;
            border-left: 3px solid black;
            transform: rotate(-45deg);
            position: absolute;
            top: 5px;
            left: 8px;
        }
    }

    & > .buttons-bar {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 15px 10px;
        z-index: 15;
        display: flex;
        width: 100%;

        button:not(:first-child) {
            margin-left: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        & > .buttons-bar {
            width: 100%;
            max-width: unset;
        }
    }
}

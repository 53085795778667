@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.confirmation-page {
    .profile-setup-steps {
        display: flex;
        width: 100%;
        margin: 0 -5px;

        & > * {
            margin: 5px;
            flex: 1 1 33%;
        }
    }

    @include media-breakpoint-down(lg) {
        .profile-setup-steps {
            display: block;
        }
    }
}

.print-play {
    .print-option {
        flex: 1 1 50%;
        margin: 8px;
        border: 1px solid #ccc;
        border-radius: 8px;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 500;
        max-width: 150px;
        padding: 10px;

        &.selected {
            background: #eee;
        }

        &:hover {
            background: #e7e7e7;
        }

        img {
            width: 70%;
            margin-bottom: 10px;
        }
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.contact-card {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1rem;

    & > * {
        margin: 0rem 0.5rem;
    }

    .MuiFormControlLabel-root {
        margin: 0;
        margin-left: -15px;
        margin-right: -15px;
    }

    .contact-name {
        display: flex;
        align-items: center;

        span {
            font-size: 1.25rem;
            font-weight: bold;
            flex-grow: 1;
            padding-right: 1rem;
        }
    }

    .alternate-contact-info {
        flex-grow: 1;
        text-align: left;
        flex-basis: 30%;
    }

    .profile-picture {
        flex-shrink: 0;
        margin-right: 0.5rem;
    }

    .contact-button {
        flex-basis: 35%;
        border: none;
        background: none;
        display: flex;
        text-align: left;
        align-items: center;
        flex-grow: 1;
    }

    .contact-info {
        flex-grow: 1;
    }

    .contact-actions {
        text-align: right;
        flex-grow: 1;
    }

    p:last-child {
        margin-bottom: 0;
    }

    .dropdown-menu-container {
        display: inline-block;
    }

    .remove-icon,
    .dropdown-button {
        cursor: pointer;
        width: 36px;
        height: 36px;
    }

    @include media-breakpoint-down(md) {
        padding: 0.5rem;
        flex-wrap: wrap;

        .MuiFormControlLabel-root {
            margin-left: -8px;
        }

        .contact-button {
            flex-grow: 1;
        }

        .alternate-contact-info {
            flex-basis: 100%;
        }

        .contact-actions {
            order: 4;
            flex-basis: 100%;
            text-align: center;
        }
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.documents-list {
    display: table;
    width: 100%;

    .d-md-table-cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        padding: 0.5rem;
    }

    .document-icon {
        img {
            width: 24px;
            height: 24px;
        }
    }

    .documents-list-header {
        font-weight: 500;
    }

    .documents-list-item {
        display: table-row;
        cursor: pointer;
    }

    .documents-list-item:nth-child(odd) {
        background: #eee;
    }

    .remove-icon {
        cursor: pointer;
        width: 30px;
        height: 30px;
    }

    @include media-breakpoint-down(sm) {
        .documents-list-item {
            display: flex;
            align-items: center;

            & > div {
                flex-shrink: 0;
            }

            .document-details {
                flex-grow: 1;
                flex-shrink: 1;
                padding: 0.5rem;
            }
        }
    }
}

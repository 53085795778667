.share-my-playbook {
    .playlist-container {
        position: relative;

        button {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
        }
    }

    .play-list .buttons-bar {
        display: none;
    }
}

@import '@labradorsports/components/variables.scss';

.share-play-team-upsell {
    overflow: hidden;

    .upsell-overlay {
        height: calc(100% - $buttons-bar-height);
        position: absolute;
        top: 0;

        .upsell-container {
            left: 0;
            text-align: left;

            &:before {
                left: unset;
                bottom: 0;
                right: 0;
                transform-origin: right bottom;
                transform: rotate(-15deg);
            }
        }
    }
}

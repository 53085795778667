@import '@labradorsports/components/variables.scss';

.play-field {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    .delete-player {
        position: absolute;
        left: 10px;
        bottom: 10px;
    }
}

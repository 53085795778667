@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.tool-group-button {
    display: inline-block;

    .tool {
        .tool-name {
            position: relative;
            padding-right: 18px;
            display: inline-block;
            white-space: nowrap;

            &::after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 7.5px 0 7.5px;
                border-color: #787878 transparent transparent transparent;
                position: absolute;
                top: 6px;
                right: 0;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        flex: 0;

        .tool {
            .tool-name {
                padding-right: 14px;
                &::after {
                    border-width: 8px 6px 0 6px;
                }
            }
        }
    }
}

.game-list {
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    margin: 0 -1rem;
    padding-left: 1rem;

    .game-date {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.stripe-card-field {
    margin-top: 16px;
    margin-bottom: 8px;

    .StripeElement {
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 5px;
        padding: 15px;
        max-width: 400px;
        position: relative;
        padding: 16px;

        &:hover {
            border-color: rgba(0, 0, 0, 0.87);
        }
    }

    .StripeElement--focus {
        padding: 15px;
        border: 2px solid rgb(0, 188, 212);

        &:hover {
            border: 2px solid rgb(0, 188, 212);
        }
    }

    @include media-breakpoint-down(sm) {
        .StripeElement {
            max-width: none;
        }
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '@labradorsports/components/variables.scss';

.community-play {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding-top: 1rem;

    .fullscreen {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .community-play-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: 900px;
    }

    .play-editor-container {
        border: 1px solid #ccc;
        border-bottom: none;
        width: 100%;
        height: 100%;
        max-height: 800px;
        padding-bottom: calc(max(56.5%, 600px));
        position: relative;

        .discrete-frame-timeline,
        .frame-buttons {
            box-shadow: none;
        }
    }

    .play-details-container {
        margin-bottom: 10px;
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
    }

    .community-play-comments {
        box-shadow: none;
        border: 1px solid #ccc;
        border-left: none;
        flex-basis: 30%;
        margin-bottom: 10px;
    }

    @include media-breakpoint-down(sm) {
        .play-editor-container {
            padding-bottom: 500px;
        }
    }

    @include media-breakpoint-down(xl) {
        flex-direction: column;
        height: 100%;

        .community-play-content {
            min-height: 100%;
            max-width: none;
        }

        .community-play-comments {
            max-width: 800px;
            margin: auto;
            width: 100%;
            border-left: 1px solid #ccc;
            margin-bottom: 1rem;
        }
    }
}

@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.split-panel-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;

    .left-panel {
        height: 100%;
        overflow: auto;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
        z-index: 2;
        background: $background;
        flex-shrink: 0;
        position: relative;
        transition: width 0.5s ease-in, min-width 0.5s ease-in;
        transform: translateX(0);
        @include left-panel-width;

        &.hidden {
            width: 0;
            min-width: 0;
        }
    }

    .right-panel {
        height: 100%;
        flex-grow: 1;
        background: $background;
        position: relative;
        overflow: auto;
        z-index: 1;
    }

    .hamburger-button {
        width: 26px;
        height: 26px;
        margin-right: 5px;
    }

    @include media-breakpoint-down(lg) {
        flex-direction: column;

        .left-panel {
            position: absolute;
            transform: translateX(-$left-panel-min-width);
            transition: transform 0.5s ease-in;

            &.has-menu-button {
                padding-top: 50px;
            }

            &.shown {
                transform: translateX(0);
            }
        }

        .right-panel {
            width: 100%;
        }

        .menu-reveal {
            flex: 0 0 auto;
            height: 50px;
            z-index: 3;
            padding: 8px;
            position: relative;
            background: white;
            border-bottom: 1px solid #ddd;
            line-height: 34px;

            .menu-title {
                font-size: 1.2em;
                vertical-align: middle;
                cursor: pointer;

                & > * {
                    vertical-align: middle;
                }
            }
        }

        &.no-left {
            .hamburger-button {
                display: none;
            }

            .menu-reveal .menu-title {
                cursor: default;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .left-panel {
            transform: translateX(-100%);
            box-shadow: none;

            &.shown {
                transform: translateX(0);
            }
        }
    }
}

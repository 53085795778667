.copyable-input {
    position: relative;

    .copy-icon {
        position: absolute;
        right: 14px;
        bottom: 16.5px;
    }

    input {
        padding-right: 100px;
    }
}

.share-play-public {
    .share-icon {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.8rem;
        margin: 0.5rem;

        img {
            display: inline-block;
            cursor: pointer;
            width: 46px;
            height: 46px;
        }
    }
}

.invite-preview {
    .invite-email-wrapper {
        max-height: 300px;
        margin: 1rem 0;
        position: relative;
    }

    .invite-email {
        padding: 0.5rem;
        max-height: 300px;
        overflow: auto;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            max-height: 300px;
            position: absolute;
            top: 0;
            left: 0;
            box-shadow: rgba(0, 0, 0, 0.5) 0 0 5px inset;
        }
    }
}

@import '@labradorsports/components/variables.scss';

.post-feed-item {
    .post-comments {
        background: #eee;
        padding: 0.5rem;

        h4 {
            text-transform: uppercase;
            font-size: 1rem;
        }
    }

    b {
        font-weight: 500;
    }

    .profile-picture {
        margin-right: 0.5rem;
    }
}

.team-color-picker {
    display: inline-block;
    position: relative;
    padding: 5px;

    .color-swatch {
        vertical-align: middle;
        display: inline-block;
        border: 3px solid #ccc;
        border-radius: 1px;
        width: 35px;
        height: 35px;
    }

    .chrome-picker {
        position: absolute;
        z-index: 1;
        bottom: 100%;
    }
}

.checkbox-list {
    list-style: none;
    padding: 0;

    &.two-cols {
        display: flex;
        flex-wrap: wrap;

        li {
            flex-basis: 50%;
        }
    }

    .sub-label {
        display: block;
        font-size: 12px;
    }
}

@import '../../variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.panel-container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.panel-header {
    flex: 0 0 auto;

    .panel-content {
        background: none;
    }
}

.panel-content {
    padding: 1rem;
    flex: 1 1 100%;
    background: $background;
    width: 100%;
    overflow: auto;

    &.no-padding {
        padding: 0;
    }
}

@include media-breakpoint-down(sm) {
    .panel-header {
        padding: 0.5rem;
    }

    .panel-content {
        padding: 0.5rem;

        &.no-padding {
            padding: 0;
        }

        & > .card {
            margin-left: -0.25rem;
            margin-right: -0.25rem;
        }
    }
}

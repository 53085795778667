@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.header-nav {
    display: inline-block;

    a {
        color: inherit;
        display: inline-block;
        text-transform: uppercase;
        height: 100%;
        padding: 0 15px;
        line-height: $header-menu-height;
        border-bottom: 3px solid transparent;
        font-size: 0.8em;
        text-decoration: none;

        &:hover {
            background: darken(white, 10%);
        }

        &.active {
            border-bottom: 3px solid $primary-blue;
        }
    }

    .community-link {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        float: right;

        .new-badge {
            color: white;
            background: $orange;
            padding: 3px;
            line-height: 1rem;
            height: calc(1rem + 6px);
            margin-right: 0.5rem;
            border-radius: 3px;
        }
    }

    @include media-breakpoint-down(sm) {
        display: block;

        a {
            display: block;
            font-weight: 500;
            font-size: 0.875rem;

            &.active {
                border-bottom: none;
            }
        }
    }
}

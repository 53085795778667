.edit-player-form {
    .alternate-contact-info {
        display: flex;
        align-items: center;
    }

    .d-flex .MuiFormControl-root {
        flex-basis: 50%;
    }
}

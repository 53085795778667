.circle-color-picker {
    .circle-picker {
        flex-basis: 170px;
        flex-shrink: 1;

        & > span:last-child {
            position: relative;

            &:after {
                content: '+';
                font-size: 22px;
                position: absolute;
                top: -3px;
                left: 8px;
                pointer-events: none;
            }

            &:hover {
                &:after {
                    transform: scale(1.2);
                }
            }
        }
    }

    .chrome-picker {
        width: 190px !important;
        box-shadow: none !important;
        margin: auto;
    }
}

@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.billing-status-banner {
    font-size: 1.1rem;
    color: white;
    padding: 1rem;
    display: flex;
    align-items: center;
    background: $primary-blue;

    &.banner-danger {
        background: $red;
    }

    &.banner-warning {
        background: $orange;
    }

    .status-right {
        flex-grow: 1;
        text-align: right;
    }

    @include media-breakpoint-down(sm) {
        display: block;

        .status-right {
            text-align: left;
        }
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.field-setup-toolbox {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-x: auto;

    .field-setup-header {
        font-size: 1.2rem;

        img {
            width: 30px;
            height: 30px;
            margin-right: 0.25rem;
            vertical-align: middle;
        }
    }

    .tools {
        flex: 1 1 auto;
        width: 160px;
    }

    @include media-breakpoint-down(sm) {
        flex-direction: row;
        justify-content: start;

        .tool-container {
            display: flex;
        }

        .tools {
            display: flex;
            width: auto;
        }

        .player-tool-menu {
            position: absolute;
            bottom: 100%;
            background: white;
            box-shadow: 1px 0px 3px 1px rgba(0, 0, 0, 0.5);
            display: flex;
            width: 100%;
            padding: 4px;
        }
    }
}

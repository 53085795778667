@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.registration-badge {
    text-transform: uppercase;
    display: inline;
    padding: 0.3rem 0.7rem;
    border-radius: 6px;
    font-size: 0.8rem;

    &.registered {
        color: #777;
    }

    &.unregistered {
        background: #f8d7da;
        color: #721c24;
    }

    @include media-breakpoint-down(lg) {
        font-size: 0.7rem;
        padding: 0.5rem;
    }
}

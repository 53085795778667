@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.follow-social-modal-header {
    display: none;
}

.simple-modal {
    .follow-social-modal-header {
        display: block;
    }
}

.app-store-badges {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.5rem;

    a {
        width: 160px;
    }

    img {
        width: 100%;
    }

    .apple-app-store {
        padding: 0 10px;
    }
}

.profile-setup-step {
    text-align: center;
    display: block;

    .step-icon {
        display: inline-block;
        width: 60px;
        height: 60px;
        background: #eee;
        border-radius: 50%;
        padding: 10px;
        margin: 0.5rem;
        position: relative;

        img {
            width: 40px;
            height: 40px;
        }

        .completed {
            position: absolute;
            bottom: -3px;
            right: -3px;
            width: 20px;
            height: 20px;
        }
    }

    .step-title {
        min-height: 50px;
    }

    .step-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &.compact {
        .step-content {
            cursor: pointer;
        }
    }

    .step-description {
        min-height: 100px;
    }

    .step-buttons {
        text-align: center;
    }

    @include media-breakpoint-down(lg) {
        &.responsive {
            &:not(.compact) {
                .step-content {
                    flex-direction: row;
                }
            }

            .step-icon {
                flex: 0 0 auto;
            }

            .step-details {
                flex: 1 1 100%;
                margin-left: 15px;
            }

            .step-title {
                min-height: auto;
                text-align: left;
            }

            .step-description {
                min-height: auto;
                text-align: left;
            }

            .step-buttons {
                text-align: right;
            }
        }
    }
}

.zoom-buttons {
    width: 24px;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;

    .zoom-in,
    .zoom-out {
        width: 24px;
        height: 24px;
        background: white;
        position: relative;
        padding: 2px;

        & > * {
            opacity: 0.5;
        }

        &.enabled * {
            opacity: 1;
        }

        &.enabled {
            cursor: pointer;

            &:hover {
                background: #ddd;
            }
        }
    }

    .zoom-in {
        border-bottom: 1px solid #ccc;

        img {
            width: 18px;
            height: 18px;
            vertical-align: top;
        }
    }

    .zoom-out span {
        position: absolute;
        background: #646f76;
        width: 14px;
        height: 2px;
        left: 4px;
        top: 11px;
    }
}

@import '@labradorsports/components/variables.scss';

.play-list-folder {
    .folder-name {
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &.renaming:hover {
        background: white;
    }

    .dropdown-menu-container {
        flex: 0 0 auto;
        margin-left: 0.25rem;
    }

    .folder-icon {
        width: 26px;
        height: 26px;
        margin-right: 8px;
        flex: 0 0 auto;
    }

    .play-count {
        white-space: nowrap;
    }
}

.play-tags {
    .header-label {
        margin-top: 16px;
    }

    .d-flex {
        margin-top: 16px;

        .header-label {
            margin-top: 0;
            flex: 1 1 auto;
        }
    }

    .tag-label {
        margin-bottom: 0;
    }

    .tag-checkbox {
        padding: 6px;
        padding-left: 12px;
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.billing-info-page {
    @include media-breakpoint-down(sm) {
        .MuiFormControl-root {
            width: 100%;
        }
    }
}

.folder-list {
    .folder-list-item {
        .expand-icon {
            display: inline-block;
            width: 14px;
            text-align: center;
        }

        &:not(.disabled) {
            & > .folder-name {
                cursor: pointer;

                &:hover {
                    background: #efefef;
                }

                &.selected {
                    background: #a8cfdb;
                }
            }
        }
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '@labradorsports/components/variables.scss';

.frame-preview {
    width: 100%;
    height: 100%;
    background: white;

    .player.has-ball {
        stroke: black;
        fill: white;
    }
}

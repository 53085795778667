.upload-document-modal {
    .file-drop-zone {
        width: 100%;
        height: 300px;
        text-align: center;
        background: #eee;
        border: 1px dashed #ddd;
        border-radius: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .file-type-icon {
            width: 120px;
            height: 120px;
            margin-bottom: 1rem;
        }
    }

    .file-upload {
        label {
            cursor: pointer;
            color: #07c;
            font-weight: 500;
        }
    }

    .file-input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
}

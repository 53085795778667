.ack-documents-warning {
    &.card {
        margin-bottom: 2rem;
    }

    img {
        width: 36px;
        height: 36px;
        margin-right: 1rem;
    }

    .ack-doc {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 0.5rem;
        display: flex;
    }
}

@import '@labradorsports/components/variables.scss';

.my-playbook-panel {
    height: 100%;

    .ptr {
        height: calc(100% - 52px);

        &.show-playbook-limit {
            height: calc(100% - 122px);
        }
    }

    .small-logo {
        width: 48px;
        height: 48px;
        position: absolute;
        opacity: 0.3;
        left: 50%;
        margin-left: -24px;
        top: 0;
    }

    .MuiTab-root,
    .MuiTabs-root {
        min-height: 52px;
        min-width: auto;
    }

    .panel-tabs {
        position: relative;

        .MuiTab-root {
            padding: 12px 12px;
            font-size: 0.8em;
        }
    }

    .my-playbook-limit {
        display: flex;
        height: 70px;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
        font-size: 0.8rem;
        z-index: 10;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: white;

        .used-plays {
            .play-count {
                font-size: 1.5rem;

                &.over-limit {
                    color: $red;
                }
            }
        }

        button {
            background: $green;
            color: white;
        }
    }

    .play-list.has-selected {
        margin-bottom: $buttons-bar-height;

        .move-to-folder-container {
            bottom: $buttons-bar-height;
        }
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.create-scouting-report-summary {
    .opponent-summary {
        .MuiFormControl-root {
            max-width: 120px;
        }
    }

    .item-added-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
    }

    .summary-film-url-input {
        min-width: 150px;
        margin: 0;
    }

    @include media-breakpoint-down(sm) {
        .opponent-summary {
            .MuiFormControl-root {
                max-width: none;
            }
        }
    }
}

@import '../../variables.scss';

.progress-bar {
    height: 5px;
    background: $dark-blue;

    .progress {
        background: $orange;
        transition: width 0.3s;
    }
}

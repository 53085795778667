@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.community-card {
    .site-logo {
        max-height: 44px;
        max-width: 44px;
        margin-right: 0.5rem;
    }

    .community-play-list {
        grid-template-columns: 0.5fr 1fr 1fr 0.5fr;

        :first-child {
            grid-column-start: 2;
        }
    }

    @include media-breakpoint-down(lg) {
        .community-play-list {
            grid-template-columns: 0.25fr 1fr 0.25fr;

            & > :nth-child(2) {
                display: none;
            }
        }
    }
}

.player {
    &.faded {
        opacity: 0.5;
    }

    text {
        fill: white;
        text-anchor: middle;

        &.subscript {
            fill: black;
            font-size: 0.7em;
        }
    }

    &.negative {
        text {
            fill: black;
        }
    }
}

.create-event-page {
    .MuiTabs-root {
        border-bottom: 1px solid #ddd;
        margin-bottom: 0.5rem;
    }

    .color-palette {
        max-width: 250px;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 10px;
    }

    .color-palette-label {
        margin-left: 14px;
        font-size: 0.75em;
        color: #767676;
        text-transform: uppercase;
    }

    .color-palette-item {
        flex: 0 0 20%;
        padding: 2px;
        border-radius: 4px;
        border: 2px solid transparent;

        .MuiIconButton-label > span {
            width: 42px;
            height: 42px;
            border-radius: 2px;
            display: inline-block;
        }

        &.color-palette-item-checked {
            border: 2px solid #333;
        }

        .other {
            background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
        }
    }
}

@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.user-profile-preview.open {
    background: white;
    padding: 1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    left: 100%;
    margin-left: 5px;
    top: 0;
    z-index: 12;
    width: auto;
    bottom: auto;

    @include media-breakpoint-down(sm) {
        position: fixed;
        margin-left: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        top: auto;
    }
}

.app-container .user-profile-preview {
    @include media-breakpoint-down(sm) {
        bottom: $app-chrome-nav-height;
    }
}

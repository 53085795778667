.bulk-event-cancel-modal {
    .event-pill {
        margin-right: 0.5rem;
        max-width: 20%;
        width: 100%;

        img {
            position: absolute;
            right: 5px;
            top: 50%;
            margin-top: -10px;
            width: 20px;
            height: 20px;
        }
    }
}

@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.editor-menu {
    max-width: 200px;
    background: white;
    border-radius: 4px;
    box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 11;
    max-height: calc(100% - 20px);
    bottom: $frame-timeline-height;
    right: 0;
    transition: opacity 0.1s linear, transform 0.1s linear;
    opacity: 0;
    transform: translateY(10px);

    hr {
        margin: 0;
    }

    .close-button {
        right: 3px;
        top: 0;
        z-index: 1;
    }

    &.editor-menu-enter {
        opacity: 0;
        transform: translateY(10px);
    }

    &.editor-menu-enter.editor-menu-enter-active {
        opacity: 0;
        transform: translateY(10px);
    }

    &.editor-menu-enter-done {
        opacity: 1;
        transform: translateY(0);
    }

    &.editor-menu-exit {
        opacity: 1;
        transform: translateY(0);
    }

    &.editor-menu-exit.editor-menu-exit-active {
        opacity: 0;
        transform: translateY(10px);
    }

    &.editor-menu-exit-done {
        opacity: 0;
        transform: translateY(10px);
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: unset;
        position: fixed;
        bottom: $app-bottom;
        right: 0;
        z-index: 1002;
    }
}

.app-container {
    .editor-menu {
        bottom: calc($app-chrome-nav-height + $frame-timeline-height);

        @include media-breakpoint-down(sm) {
            bottom: $app-bottom;
        }
    }
}

.with-fullscreen {
    display: none;
}

.fullscreen.fullscreen-enabled {
    .with-fullscreen {
        display: inline;
    }
}

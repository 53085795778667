.post-video-embed {
    .video-thumbnail {
        width: 90px;
        flex-shrink: 0;
    }

    .video-details {
        width: 80%;
    }

    .video-title,
    .video-description {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .video-title {
        white-space: nowrap;
    }
}

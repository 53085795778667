@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.schedule-page {
    &.split-panel-container .split-panel .right-panel {
        overflow: hidden;
    }

    .team-header {
        margin-bottom: 1rem;
    }

    .schedule-side-panel {
        height: 100%;

        .ptr {
            height: calc(100% - 52px);
        }

        .buttons-bar {
            position: absolute;
            bottom: 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 15px 10px;

            button:not(:first-child) {
                margin-left: 0;
            }

            .rsvp-toggle {
                width: 120px;
            }
        }
    }

    .schedule-feed-container {
        height: 100%;
        position: relative;

        &.has-selected {
            padding-bottom: 90px;
        }
    }

    .schedule-feed {
        flex: 1 1 auto;
    }

    .menu-reveal {
        display: none;
    }

    .schedule-home-page {
        height: 100%;
        overflow: auto;
        position: relative;
    }

    @include media-breakpoint-down(sm) {
        .schedule-side-panel .schedule-feed-container .calendar-view-button {
            right: 15%;
            top: -5px;
        }
    }
}

@import '@labradorsports/components/variables.scss';

.profile-picture {
    width: 48px;
    height: 48px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid $dark-blue;

    img {
        background: #eee;
        width: 100%;
        height: 100%;
    }
}

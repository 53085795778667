@import '../../variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.upsell-overlay {
    position: fixed;
    top: $header-height;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    background: rgba(0, 0, 0, 0.4);
    overflow: hidden;

    .upsell-container {
        position: absolute;
        right: 0;
        top: 0;
        background: $dark-blue;
        padding: 1rem;
        text-align: right;
        color: white;
        width: 350px;
        min-height: 300px;

        &:before {
            content: ' ';
            position: absolute;
            width: 400%;
            height: 400%;
            transform: rotate(-45deg);
            transform-origin: left center;
            background: $dark-blue;
            z-index: -1;
            bottom: -200%;
            left: 0;
        }
    }

    h1 {
        color: $orange;
        font-weight: bolder;
    }
}

@include media-breakpoint-down(md) {
    .upsell-overlay {
        top: $short-header-height;

        .upsell-container {
            left: 0;
            text-align: left;

            &:before {
                left: unset;
                bottom: 0;
                right: 0;
                transform-origin: right bottom;
                transform: rotate(-15deg);
            }
        }
    }
}

.app-container {
    .upsell-overlay {
        top: $app-chrome-header-height;
    }
}

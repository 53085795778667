@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.create-scouting-report-page {
    height: 100%;

    .panel-container:not(.create-scouting-report-summary) .panel-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }

    .opponent-summary {
        .MuiFormControl-root {
            max-width: 120px;
        }
    }

    @include media-breakpoint-down(sm) {
        .opponent-summary {
            .MuiFormControl-root {
                max-width: none;
            }
        }
    }
}

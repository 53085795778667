.website-preview {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
    max-width: 400px;
    overflow: hidden;
    align-items: center;
    cursor: pointer;

    img {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        margin-right: 0.5rem;
    }

    div {
        overflow: hidden;
    }

    .title {
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .description {
        line-height: 1rem;
        height: 2rem;
    }
}

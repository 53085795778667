.community-play-details {
    .play-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        flex-grow: 1;
    }

    .engagements {
        white-space: nowrap;
        margin-left: 0.25rem;
    }

    .play-details {
        display: flex;
        flex-direction: row;

        em {
            margin-right: 0.5rem;
            margin-bottom: 0;
            display: inline-block;
        }

        a {
            color: #333;
            text-decoration: none;

            &:hover {
                color: #333;
            }
        }
    }

    .divider {
        display: inline-block;
        padding: 0 5px;
    }

    .description .quill-wrapper .ql-container {
        margin-bottom: 0.5rem;
        max-height: 10em;
        overflow: auto;
        word-wrap: break-word;
        font-size: 13px;
    }

    .copy-button {
        background: none;
        border: none;
        height: 24px;
        padding: 2px;
        line-height: 24px;
        line-height: 18px;
        margin-left: 2px;

        &:hover {
            background: #eee;
        }

        img {
            width: 20px;
            height: 20px;
            margin-right: 3px;
        }
    }

    .sharing-details {
        display: flex;
    }
}

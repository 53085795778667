@import '../../variables.scss';

.circle-progress-bar {
    circle {
        stroke: $dark-blue;
        stroke-width: 2;
        fill: none;
    }

    .progress {
        stroke: $orange;
        stroke-width: 3;
        fill: none;
    }
}

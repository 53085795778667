@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '../../variables.scss';

.slider-input-combo {
    align-items: center;
    margin: 5px 0;

    .slider-label {
        text-transform: uppercase;

        .slider-subtitle {
            display: block;

            @include media-breakpoint-down(md) {
                display: inline-block;
                padding-left: 0.5rem;
            }
        }
    }

    .rc-slider-dot {
        height: 20px;
        bottom: -8px;
        border-radius: 0;
        border: none;
        background: none;

        &:before {
            content: '';
            position: absolute;
            left: 4px;
            border-left: 1px solid black;
            height: 20px;
            top: 0;
        }
    }

    .rc-slider-handle {
        background: $dark-blue;
        border-color: $dark-blue;
    }

    .rc-slider-track {
        background-color: rgba($dark-blue, 0.5);
    }
}

.share-play-community {
    .playlist-container {
        position: relative;

        button {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
        }
    }
}

.attendance-badge {
    border-radius: 1rem;
    width: 120px;
    text-align: center;
    display: inline-block;

    &.editable {
        cursor: pointer;
    }

    &.going {
        background-color: $green;
    }

    &.maybe {
        background-color: $yellow;
    }

    &.notgoing {
        background-color: $red;
    }
}

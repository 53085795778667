.practice-template-list {
    height: 100%;
    overflow: auto;

    .practice-template-list-item {
        align-items: flex-start;
        border-bottom: 1px solid #ccc;
        padding: 0.5rem;
    }
}

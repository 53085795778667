@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.simple-modal {
    .MuiPaper-root {
        text-align: center;
        margin: 0;
        padding: 15px;
    }

    .sm-btn {
        margin: 0 10px 5px;
    }

    @include media-breakpoint-down(xl) {
        margin-left: auto;
        margin-right: auto;
    }

    @include media-breakpoint-down(sm) {
        width: auto;
        margin-left: 0;
        margin-right: 0;

        .MuiPaper-root.MuiDialog-paperScrollBody {
            max-width: calc(100% - 10px);
        }
    }
}

@import '@labradorsports/components/variables.scss';

.save-button {
    @keyframes save-button-glow {
        0% {
            box-shadow: 0px 0px 0px 0px rgba($primary-blue, 0.4);
        }

        80%,
        100% {
            box-shadow: 0px 0px 3px 3px rgba($primary-blue, 0.4);
        }
    }

    &.glow {
        animation: save-button-glow 0.7s 6 alternate;
    }
}

.move-display {
    &.fade-1 {
        opacity: 0.7;
    }

    &.fade-2 {
        opacity: 0.4;
    }

    .subscript {
        text-anchor: middle;
        font-size: 0.7em;
        stroke: none;
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.tool-group {
    display: inline-block;
    position: relative;
    flex-basis: 0;

    .tool-list {
        position: absolute;
        background: white;
        border: 1px solid #ddd;
        z-index: 1;
    }

    @include media-breakpoint-down(sm) {
        display: flex;

        .tool {
            flex: 0 0 auto;
        }

        .tool-list {
            position: static;
            display: inline-block;
            border: none;
            border-left: 1px solid #ddd;
            padding-left: 4px;
            white-space: nowrap;
        }
    }
}

@import '../../variables.scss';

.buttons-bar {
    height: $buttons-bar-height;
    background: white;
    border-top: 1px solid #ddd;
    z-index: 1;
    text-align: right;
    padding: 15px;

    & > button:not(:first-child) {
        margin-left: 1rem;
    }
}

.post-comment {
    position: relative;
    padding: 0.5rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 1px 6px -3px rgba(0, 0, 0, 0.5);

    & + .post-comment {
        margin-top: 0.5rem;
    }

    .delete-button {
        background: white;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        text-align: center;
        line-height: 14px;
        position: absolute;
        right: -7px;
        top: -7px;
        box-shadow: -1px 1px 6px -3px rgba(0, 0, 0, 0.5);
    }

    .profile-picture {
        width: 36px;
        height: 36px;
    }
}

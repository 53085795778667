.public-shared-play-page {
    height: 100%;

    .play-editor {
        flex: 1 1 auto;
    }

    .public-share-embed-footer {
        flex: 0 0 auto;
    }

    & > .panel-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        .fullscreen {
            height: 100%;
        }
    }
}

.public-play-load-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

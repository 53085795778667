.signup-form {
    text-align: left;

    h2 {
        margin-bottom: 1rem;
    }

    a {
        cursor: pointer;
    }

    .error-text {
        display: block;
    }

    .first-name-field {
        width: 50%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .last-name-field {
        width: 50%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

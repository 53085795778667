@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.schedule-event-card.card {
    border-left: 5px solid var(--event-color);
    transition: box-shadow 0.15s ease-in, margin 0.15s ease-in;
    box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.5);
    margin: 0 0 8px 0;
    display: flex;
    flex-direction: row;
    justify-content: stretch;

    &:hover {
        margin: -1px 1px 9px -1px;
        box-shadow: 1px 1px 9px -1px rgba(0, 0, 0, 0.5);
    }

    .event-type {
        color: var(--event-color);
        text-transform: uppercase;
    }

    .rsvp-buttons {
        display: inline-block;
        margin: 0 0.5rem;
    }

    .game-team {
        font-weight: 500;
    }

    .event-actions {
        display: flex;
        align-items: center;

        & > * {
            flex: 0 0 auto;
        }

        .rsvp-toggle {
            flex: 1 1 auto;
        }
    }

    .event-details {
        border-bottom: 1px solid #ddd;
    }

    .loading-icon {
        width: 20px;
        height: 20px;
    }
}

.player-rating-page {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;

    .ratings-dashboard {
        padding: 15px;
    }

    .rating-category {
        display: flex;

        .card {
            width: 100%;
            padding: 15px;
        }

        .rating {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .rating-label {
        text-transform: uppercase;

        .small {
            margin-left: 0.5rem;
        }
    }
}

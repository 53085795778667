@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.content-editor {
    border-left: 4px solid $primary-blue;
    background: #eee;
    margin-bottom: 0.5rem;

    .play-link-pill {
        display: inline-block;
        pointer-events: none;

        span {
            border-radius: 15px;
            background: #ddd;
            display: inline-block;
            padding: 3px 10px;
            min-width: 50px;
            margin: 0 5px 5px;
        }

        &:first-child {
            span {
                margin-left: 0;
            }
        }
    }

    .quill {
        padding: 0 10px 10px;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;

        .ql-container {
            background: white;
            border-radius: 4px;
            min-height: 90px;
        }
    }

    .ql-toolbar {
        border-bottom: none;
        border-left: none;

        .ql-formats.float-end {
            margin-right: 0;
        }

        .ql-play-link {
            width: auto;
            height: auto;
        }
    }

    .ql-tooltip {
        padding: 5px;

        &:before {
            content: '';
            margin-right: 0;
        }

        a {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 14px;
            cursor: pointer;
            text-decoration: none;
            color: #0077cc;
            padding: 5px 12px;

            &:hover {
                background: #f0f0f0;
            }
        }
    }

    .roster-player[data-added='true'] {
        font-weight: bold;
    }
}

@import '../../variables.scss';

.jumbo-img {
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .container {
        position: relative;
        z-index: 2;
    }

    &.faded-background:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.9);
        top: 0;
        left: 0;
        z-index: 1;
    }
}

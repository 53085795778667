@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.play-toolbox {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .help-button {
        border-top: 1px solid #ddd;
        width: 100%;

        img {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }

    .tools {
        flex-grow: 1;
        position: relative;
        text-align: left;
        width: 160px;
    }

    .tool-section-header {
        text-align: left;
        font-size: 1.2em;
        margin: 14px;

        & > * {
            vertical-align: middle;
        }

        .player-icon {
            margin-right: 10px;
        }
    }

    @include media-breakpoint-down(sm) {
        flex-direction: row;
        justify-content: start;

        .help-button {
            border: none;
            border-left: 1px solid #ddd;
            padding: 0 4px;
            flex: 0 1 70px;
            text-align: center;
            background: none;
            font-size: 0.8em;

            img {
                width: 40px;
                height: 40px;
                display: inline-block;
                margin-right: 0;
            }
        }

        .tools {
            display: flex;
            width: auto;
        }

        .tool-container {
            display: flex;
            flex: 0;
        }
    }
}

@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);

.header-bar {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;

    .close-menu {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 10px;
        right: 13px;
        cursor: pointer;
        color: #333;
        font-size: 4.3rem;
        line-height: 40px;
        text-align: center;
        opacity: 0;
        transform: scale(0, 0.85);
        transition: transform 0.15s $ease-out-quad, opacity 0.00001s $ease-out-quad 0.15s;
    }

    .avatar-container {
        backface-visibility: hidden;

        .avatar {
            transform: scale(1, 1);
            transition: transform 0.15s $ease-out-quad 0.15s, opacity 0.00001s $ease-out-quad 0.15s;
        }

        &.menu-open {
            .avatar {
                transform: scale(0, 0.85);
                transition: transform 0.15s $ease-out-quad, opacity 0.00001s $ease-out-quad 0.15s;
            }

            .close-menu {
                transition: transform 0.15s $ease-out-quad 0.15s, opacity 0.00001s $ease-out-quad 0.15s;
                transform: scale(1, 1);
                opacity: 1;
            }
        }
    }

    .login-popover {
        position: absolute;
        right: 5px;
        top: $header-height + 5px;
        padding: 20px;
        background: rgba(255, 255, 255, 0.98);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
        transition: top 0.2s ease-in;
        z-index: -1; // z-index set relative to container, not page
        max-width: 400px;
        opacity: 0;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #aaa;
        font-size: 1.5em;
        cursor: pointer;
    }

    .signin-form-enter {
        opacity: 0.01;

        @include media-breakpoint-up(md) {
            top: $header-height - 15px;
        }
    }

    .signin-form-enter.signin-form-enter-active {
        opacity: 1;
        transition: top 0.2s ease-out, opacity 0.2s ease-out;

        @include media-breakpoint-up(md) {
            top: $header-height + 5px;
        }
    }

    .signin-form-enter-done {
        opacity: 1;
    }

    .signin-form-exit {
        opacity: 1;

        @include media-breakpoint-up(sm) {
            top: $header-height + 5px;
        }
    }

    .signin-form-exit.signin-form-exit-active {
        opacity: 0.01;
        transition: top 0.2s ease-out, opacity 0.2s ease-out;

        @include media-breakpoint-up(sm) {
            top: $header-height - 15px;
        }
    }

    .signin-form-exit-done {
        display: none;
    }

    &.small-header {
        .login-popover {
            top: $short-header-height + 5px;
        }

        .header-contents {
            height: $short-header-height;

            @include media-breakpoint-up(md) {
                box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.5);
            }

            .logo-img {
                height: $short-header-height * 0.75;
            }
        }
    }

    .header-contents {
        height: $header-height;
        display: flex;
        align-items: center;
        position: relative;
        box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
        background: rgba(255, 255, 255, 0.98);
        transition: height 0.2s ease-in, box-shadow 0.2s ease-in;
        z-index: 1;

        .logo-img {
            display: inline-block;
            margin: 0 10px 0 15px;
            height: $header-height * 0.75;
            transition: width, height 0.2s ease-in;

            @include media-breakpoint-down(lg) {
                height: $header-height * 0.5;
            }
        }

        .bs {
            margin-right: 5px;

            &.blue {
                border: 1px solid #003e5e;
                color: #003e5e;

                &:hover {
                    background-color: #003e5e;
                    color: white;
                }
            }

            &.signup-button {
                border: 1px solid $orange;
                color: $orange;

                &:hover {
                    background-color: $orange;
                    color: white;
                }
            }
        }
    }

    .header-menu {
        height: $header-menu-height;
        background: #f0f0f0;
        box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
        margin-top: 0px;
        transition: margin-top 0.2s ease-in, box-shadow 0.2s ease-in;
        display: flex;

        .header-nav {
            flex-grow: 1;
        }
    }

    &.popover-open {
        .header-menu.hidden {
            position: absolute;
            width: 100%;
            margin-top: 0;
        }
    }

    .logo {
        flex: 1 1 auto;
        cursor: pointer;
    }

    .header-buttons {
        text-align: right;
        flex: 1 1 100%;
        position: relative;
        display: flex;
        justify-content: end;
        align-items: center;

        .teams-menu {
            text-align: left;
            min-width: 250px;
            border: none;

            .menu-button {
                border: 1px solid #003e5e;
                border-radius: 4px;
                color: #003e5e;

                .team-option:hover {
                    background: unset;
                }

                &:hover {
                    background-color: #003e5e !important;
                    color: white;
                }
            }
        }
    }

    .social-icon {
        width: 36px;
        height: 36px;
    }

    .avatar {
        flex: 0 0 auto;
        margin-right: 10px;
    }

    .mobile-header-buttons {
        display: none;
        flex-grow: 1;
    }

    &.app-header {
        height: $short-header-height;

        .header-contents {
            background: $dark-blue;
            box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
        }

        .close-menu {
            color: white;
        }

        .desktop-header-buttons {
            display: none;
        }

        .mobile-header-buttons {
            display: block;
        }

        .header-buttons {
            .teams-menu .menu-button {
                border: 1px solid white;
                color: white;

                &:hover {
                    background-color: transparent;
                }
            }
        }

        .header-menu {
            display: none;
        }
    }

    @include media-breakpoint-down(md) {
        &.popover-open {
            min-height: 70px;
            max-height: 100%;
            overflow: auto;

            .header-contents {
                box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.5);
            }
        }

        .login-popover {
            position: relative;
            max-width: inherit;
            top: 0;
            right: 0;
            z-index: 0;
            border-bottom: 1px solid #ddd;
        }

        .header-contents {
            height: $short-header-height;

            .logo-img {
                height: $short-header-height * 0.75;
            }
        }

        .desktop-header-buttons {
            display: none;
        }

        .mobile-header-buttons {
            display: block;
        }

        .header-menu {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        .header-buttons {
            flex: 1 1 100%;

            .teams-menu {
                width: 100%;
                min-width: auto;
            }
        }
    }
}

.team-logo-upload {
    display: inline-block;
    text-align: center;
    margin-right: 40px;

    .upload-window {
        border: 2px solid #eee;
        box-sizing: content-box;
        margin-bottom: 5px;
        position: relative;

        &.placeholder {
            background: #eee;

            .croppie-container {
                opacity: 0;
            }
        }
    }

    .croppie-container .cr-slider-wrap {
        width: 100%;
        transform: rotate(-90deg);
        transform-origin: 100% 0;
        left: 10px;
        position: absolute;
        top: 1px;
        margin: 0;
    }
}

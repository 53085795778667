@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.community-profile {
    height: 100%;
    width: 100%;

    .community-profile-setup {
        margin: 1rem auto;
        max-width: 500px;
        border: 1px solid #ccc;
    }

    .community-play-list {
        margin-top: 1rem;
    }

    @include media-breakpoint-down(md) {
        .community-profile-setup {
            margin: 0;
            border: none;
            max-width: none;
        }
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.attendance-page {
    padding: 1.5rem;

    .attendance-summary {
        background: #efefef;
        padding: 1rem;
        display: flex;
        margin: auto;
        max-width: 640px;
        align-items: center;

        .whole-team {
            flex: 1 1 30%;
            text-align: center;
            font-size: 1.3rem;

            .total-count {
                font-size: 2.5rem;
                font-weight: 500;
            }
        }

        .position-breakdown {
            flex: 1 1 70%;
            display: flex;
            flex-wrap: wrap;
        }

        .position-section {
            flex: 1 1 33%;
            text-align: center;
            padding: 0.5rem;

            .position-total-count {
                font-size: 1.3rem;
                font-weight: 500;
            }
        }
    }

    .attendance-details {
        margin: auto;
        margin-top: 2rem;
        max-width: 640px;
        display: flex;
        flex-wrap: wrap;

        .mark-present {
            flex: 0 0 100%;
        }

        .attendance-entry {
            flex: 0 0 50%;
        }
    }

    @include media-breakpoint-down(md) {
        .attendance-summary {
            flex-direction: column;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 1rem;

        .attendance-summary .position-section {
            flex: 1 1 50%;
        }

        .attendance-details {
            display: block;
        }
    }
}

.time-range-input {
    position: relative;
    min-width: 220px;
    padding: 8px 0;

    .start-input,
    .end-input {
        border: none;
        max-width: 48%;
        flex-grow: 0;
        min-width: auto;
        padding: 0;
    }

    .input-wrapper {
        display: flex;
        position: absolute;
        padding-left: 14px;
        padding-right: 0.5rem;
        width: 100%;
        height: 60px;
        align-items: center;
    }

    input[type='time']::-webkit-clear-button {
        display: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0;
        /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type='time']::-webkit-calendar-picker-indicator {
        background: none;
        width: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
        /* Firefox */
    }
}

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.depth-chart {
    display: flex;
    height: 100%;
    position: relative;
    user-select: none;
    overflow: hidden;

    .depth-chart-column {
        position: relative;
    }

    .roster-container {
        width: 25%;
        min-width: 350px;
        max-width: 500px;
        height: 100%;
        overflow: auto;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
        z-index: 2;
        background: white;
        flex-shrink: 0;
        position: relative;
    }

    .autosave-loading {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 0.5rem;
    }

    .depth-container {
        flex: 1 1 auto;
        height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .row {
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .depth-chart-container {
        margin: 10px 0;
    }

    .backdrop {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
    }

    .depth-chart-delete-button {
        position: absolute;
        right: 15px;
        bottom: 15px;

        &.delete-button-enter {
            bottom: -65px;
        }

        &.delete-button-enter.delete-button-enter-active {
            bottom: 15px;
            transition: bottom 0.15s ease-in;
        }

        &.delete-button-exit {
            bottom: 15px;
        }

        &.delete-button-exit.delete-button-exit-active {
            bottom: -65px;
            transition: bottom 0.15s ease-in;
        }
    }

    @include media-breakpoint-down(lg) {
        overflow: hidden;

        .roster-container {
            transition: bottom 0.15s ease-in;
            position: absolute;
            bottom: -200px;
            left: 0;
            right: 0;
            height: 200px;
            margin: 0;
            max-width: unset;
            width: 100%;

            &.shown {
                margin: 0;
                bottom: 0px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .depth-chart-delete-button {
            right: 50%;
            margin-right: -25px;
        }
    }
}

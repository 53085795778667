.fullscreen {
    &.app.fullscreen-enabled {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1200;
    }
}

@import '@labradorsports/components/variables.scss';

.footer {
    background: $dark-blue;
    color: white;
    padding: 1rem;

    a {
        color: #0fb2d1;
        font-weight: 500;

        &:hover {
            color: #aaa;
        }
    }

    .footer-top-section {
        display: flex;

        .footer-logo {
            flex-grow: 1;
            text-align: center;

            img {
                width: 70px;
                height: 70px;
                opacity: 0.5;
            }
        }
    }
}

@import '@labradorsports/components/variables.scss';

.line-editor-menu {
    circle,
    image {
        cursor: pointer;
    }

    .controls {
        display: flex;
        justify-content: center;

        & > * {
            line-height: 1;
            width: 40px;
            height: 40px;
            padding: 2px;
        }

        button {
            font-size: 2rem;
            color: $dark-blue;
            padding: 0;
            min-width: auto;
        }

        .MuiFormControl-root {
            margin: 0;
        }

        .MuiInputBase-root {
            height: 100%;
        }

        input {
            text-align: center;
            padding: 0;
        }
    }
}

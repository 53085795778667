@import '@labradorsports/components/variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

.global-search-results {
    position: absolute;
    right: 0;
    top: 100%;
    max-height: 500px;
    width: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: #ccc 0px 0px 5px 0px;
    background: white;
    text-align: left;
    padding: 0.25rem 0;

    h2 {
        padding: 0.25rem 1rem;
        font-size: 1.3rem;
    }

    .loading-icon,
    .empty-search {
        width: 32px;
        height: 32px;
        margin: 12px 0;
    }

    .search-result {
        cursor: pointer;
        padding: 0.25rem 1rem;
        font-size: 0.8rem;

        &:hover {
            background: darken(#fff, 10);
        }
    }

    .unpaid-warning {
        padding: 0.25rem 1rem;
        font-size: 0.8rem;
    }

    @include media-breakpoint-down(sm) {
        position: fixed;
        top: $short-header-height;
        width: 100%;
        height: calc(100% - $short-header-height);
        max-height: unset;
    }
}
